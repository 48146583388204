<template>
  <!-- <Navbar /> -->
  <div class="offcanvas offcanvas-start bg-light" tabindex="-1" ref="offcanvasExample" id="offcanvasExample"
    aria-labelledby="offcanvasExampleLabel">
    <div class="offcanvas-header bg-nav-client" id="slideHeader">
      <h5 class="offcanvas-title" id="offcanvasExampleLabel">
        <img :src="issuserParam.rutaLogo + issuserParam.logo" height="100" class="img-fluid" />
      </h5>
      <button type="button" id="botonCat" class="btn text-black" data-bs-dismiss="offcanvas" aria-label="Close"
        @click="vaciarCategoria">
        <fa icon="arrow-left" class="fa-2x" />
      </button>
    </div>
    <div class="offcanvas-body pt-0 px-0 border-top">
      <div class="my-0" v-if="tieneGruposCategorias">
        <ul class="list-group">
          <div v-for="categoria in categorias" :key="categoria.codigoGrupo" @mouseenter="showSubCategorias(categoria)">
            <div class="list-group-item bg-transparent border-0 border-bottom align-items-center p-0">
              <!--- CUANDO LAS CATEGORIAS TIENEN IMAGENES -->
              <div class="card justify-content-center text-white border-0 w-100 h-100" >

                <a class="nav-link text-decoration-none p-0" @click="selectCategoria(categoria)"
                  
                  data-bs-toggle="collapse" v-bind:id="'cat_' + categoria.codigoGrupo"
                  :href="'#collapse' + categoria.codigoGrupo" role="button" aria-expanded="false"
                  :aria-controls="'#collapse' + categoria.codigoGrupo" v-if="categoria.imagenGrupo">
                  <div class="row">
                    <div class="col-4 py-2">
                      <img :src="categoria.path + categoria.imagenGrupo" class="card-img img-fluid"
                        :alt="categoria.nombreGrupo" />
                    </div>
                    <div class="col-8 d-flex align-items-center justify-content-start">
                      <h5 class="card-title text-dark text-start text-wrap mb-0"
                        :class="issuserParam.fuenteSubtitulo && issuserParam.fuenteSubtitulo != '-1' ? 'subtitle-client' : ''">
                        {{ categoria.nombreGrupo }}
                      </h5>
                    </div>
                  </div>
                </a>
                <!--- SI LAS CATEGORIAS VIENEN SIN IMAGEN-->
                <a class="nav-link w-100" style="text-decoration: none !important;" data-bs-toggle="collapse"
                  :href="'#collapse' + categoria.codigoGrupo" role="button" aria-expanded="false"
                  :aria-controls="'#collapse' + categoria.codigoGrupo" @click="selectCategoria(categoria)" v-else>
                  <h5 class="text-dark mt-1"
                    :class="issuserParam.fuenteSubtitulo && issuserParam.fuenteSubtitulo != '-1' ? 'subtitle-client' : ''">
                    {{ categoria.nombreGrupo }}
                  </h5>
                </a>
              </div>
            </div>

            <div class="row subcategories-md d-none d-sm-block d-md-block d-lg-block">
              <div class="collapse" :id="'collapse' + categoria.codigoGrupo">
                <ul class="mt-0 list-group rounded-0">
                  <li class="list-group-item border border-bottom-1 li-subCategories" v-for="item in categoria.items"
                    :key="item.codigo" :class="[item.clase ? item.clase : '']" @click="selectSubCategoria(item)"><a
                      class="text-dark text-decoration-none li-sub-md"
                      :class="issuserParam.fuenteSubtitulo && issuserParam.fuenteSubtitulo != '-1' ? 'subtitle-client' : ''"
                      style="font-size: 0.80rem!important" href="#">{{
                        capitalizarPalabras(item.nombre) }}</a></li>
                </ul>
              </div>
            </div>
          </div>
        </ul>
      </div>

      <!--- SLIDE DE SUB CATEGORIAS PARA RESPONSIVE -->
      <div class="subcategories d-block d-sm-none d-md-none d-lg-none border-top"
        :class="subcategoriesVisible ? 'active' : 'inactive'">
        <div v-if="categoriaSelected != null">
          <div class="row bg-white m-0 py-3 px-2 fw-bold d-flex align-items-center" style="width: 400px!important"
            :class="issuserParam.fuenteSubtitulo && issuserParam.fuenteSubtitulo != '-1' ? 'subtitle-client' : ''">
            <fa icon="arrow-left" style="max-height: 30px!important;box-sizing: content-box;"
              @click="cerrarSubCategorias" />
            {{ categoriaSelected.nombreGrupo }}
          </div>
          <ul class="list-group border-bottom"
            :class="issuserParam.fuenteSubtitulo && issuserParam.fuenteSubtitulo != '-1' ? 'subtitle-client' : ''">
            <li v-for="subcategoria in categoriaSelected.items" :key="subcategoria.codigo"
              :class="[subcategoria.clase ? subcategoria.clase : '']"
              class="list-group-item border-0 border-top w-100 py-3" @click="selectSubCategoria(subcategoria)">
              {{ capitalizarPalabras(subcategoria.nombre) }}
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="row mx-1 mb-2">
      <span style="font-size:0.8em" class="ordenar_filtro">App.{{ app_v }} - Api.{{ api_v }}</span>
    </div>
  </div>
</template>

<script>
// import Navbar from "@/components/Navbar.vue";
import { inject, computed, ref } from "vue";
import { Offcanvas } from "bootstrap";
import { useRoute } from "vue-router";
import router from "@/router";
import { capitalizarPalabras } from "@/composable/functions";
export default {
  name: "slidebar",
  components: {
    // Navbar
  },
  data() {
    const app_v = inject("app_version");
    return {
      touchstartX: 0,
      touchstartY: 0,
      touchendX: 0,
      touchendY: 0,
      canvas: "",
      adjfactor: 100,
      iconDarkLigth: "sun",
      app_v
    };
  },
  setup() {
    const route = useRoute();
    const categorias = inject("categorias");
    const tieneGruposCategorias = inject("tieneGruposCategorias");
    const paramsProductos = inject("paramsProductos");
    const cargarProductos = inject("cargarProductos");
    const pagActive = inject("pagActive");
    const issuserParam = inject("issuserParam");
    const inicioHash = inject("inicioHash");
    const api_v = inject("api_version");
    const subcategoriesVisible = inject("subcategoriesVisible");
    const categoriaSelected = ref({});
    const categoriaSelctedGlobal = inject("categoriaSelected");
    const subSelected = inject("subSelected");
    const alturaHeader = ref("");
    const anchoPantalla = ref(screen.width);

    const subCategorias = computed(() => {
      let array = [];
      if (paramsProductos.value.grupo != -1) {
        const categoria = categorias.value.find((categoria) => {
          return categoria.codigoGrupo === paramsProductos.value.grupo;
        });
        array = categoria.items;
      } else {
        categorias.value.forEach((categoria) => {
          array.push(...categoria.items);
        });
      }
      return array;
    });

    const cerrarSubCategorias = () => {
      subcategoriesVisible.value = false;
    }
    const vaciarCategoria = () => {
      if (subSelected.value === null) {
        // categoriaSelected.value = null;
        document.querySelectorAll(".collapse.show").forEach((elemento) => {
          elemento.classList.remove('show');
        })
      }
      if (categoriaSelected.value.items.length == 0) {
        cerrarSubCategorias();
      }
    }

    const selectCategoria = (categoria) => {
      ///////////////////////////////
      //// LIMPIO LA SUB CATEGORIA ANTERIOR
      if (subSelected.value != null) {
        subSelected.value.clase = '';
      }
      subSelected.value = null;
      subcategoriesVisible.value = false;
      /////////////////

      categoriaSelected.value = categoria
      pagActive.value = 1;
      paramsProductos.value.categoria = -1;
      paramsProductos.value.orderby = 1;
      paramsProductos.value.search = "";
      paramsProductos.value.start = pagActive.value;
      paramsProductos.value.grupo = categoria.codigoGrupo;

      categoriaSelctedGlobal.value = categoriaSelected.value;
      cargarProductos(paramsProductos.value, sendFiltros);
       if(anchoPantalla.value <= 575 && categoriaSelected.value.items.length > 0){
        subcategoriesVisible.value = true;
        return;
      }
       document.getElementById("botonCat").click();
    };

    const showSubCategorias = (categoria) => {
      categoriaSelected.value = categoria
      subcategoriesVisible.value = false;
      document.querySelectorAll(".collapse.show").forEach((elemento) => {
        elemento.classList.remove('show');
      })
      document.getElementById("collapse" + categoria.codigoGrupo).setAttribute("class", "collapse show");
    }

    const selectSubCategoria = (subCategoria) => {
      if (subSelected.value != null) {
        subSelected.value.clase = '';
      }

      categoriaSelctedGlobal.value = categoriaSelected.value

      pagActive.value = 1;
      subSelected.value = subCategoria;
      subSelected.value.clase = 'selected';
      subSelected.value.codigoGrupo = categoriaSelected.value.codigoGrupo;
      paramsProductos.value.orderby = 1;
      paramsProductos.value.search = "";
      paramsProductos.value.start = pagActive.value;
      paramsProductos.value.categoria = subCategoria.codigo;
      paramsProductos.value.grupo = categoriaSelected.value.codigoGrupo;

      document.getElementById("botonCat").click();
      cargarProductos(paramsProductos.value, sendFiltros);
    };

    const sendFiltros = () => {
      if (paramsProductos.value.categoria != -1) {
        router.push({
          name: "Catalog",
          params: { categoria: categoriaSelected.value.nombreGrupo.toLowerCase(), subcategoria: subSelected.value.nombre.toLowerCase() },
        });
      } else if (paramsProductos.value.grupo != -1) {
        router.push({
          name: "Catalog",
          params: { categoria: categoriaSelected.value.nombreGrupo.toLowerCase() },
        });
      } else {
        router.push({ name: "Catalog" });
      }
    };

    const borrarFiltros = () => {
      pagActive.value = 1;
      paramsProductos.value.start = pagActive.value;
      paramsProductos.value.categoria = -1;
      paramsProductos.value.grupo = -1;
      paramsProductos.value.search = "";
      paramsProductos.value.orderby = 1;
      delete paramsProductos.value.productos;
      cargarProductos(paramsProductos.value, () => {
        inicioHash.verProductos.class = "active";
        inicioHash.verFavoritos.class = "";
        sendFiltros();
      });
    };

    const mostrarFavoritos = () => {
      pagActive.value = 1;
      paramsProductos.value.start = pagActive.value;
      paramsProductos.value.categoria = -1;
      paramsProductos.value.grupo = -1;
      paramsProductos.value.search = "";
      paramsProductos.value.orderby = 1;
      paramsProductos.value.productos = localStorage.getItem("fav") ? JSON.parse(localStorage.getItem("fav")) : "";

      cargarProductos(paramsProductos.value, () => {
        inicioHash.verProductos.class = "";
        inicioHash.verFavoritos.class = "active";
        if (route.path !== "/") {
          router.push({ name: "Home" });
        }
      });
    };

    return {
      showSubCategorias,
      capitalizarPalabras,
      alturaHeader,
      vaciarCategoria,
      cerrarSubCategorias,
      categoriaSelected,
      subcategoriesVisible,
      categorias,
      tieneGruposCategorias,
      paramsProductos,
      selectCategoria,
      issuserParam,
      subCategorias,
      selectSubCategoria,
      borrarFiltros,
      inicioHash,
      mostrarFavoritos,
      api_v
    };
  },
  mounted() {
    this.canvas = new Offcanvas(document.getElementById("offcanvasExample"));
    document.addEventListener("touchstart", this.touchstart);

    document.addEventListener("touchend", this.touchend);
    //this.instanceVisualMode();

    setTimeout(
      () => {
        this.alturaHeader = document.getElementById("slideHeader").offsetHeight + "px";
      }, 400
    )
  },
  unmounted() {
    document.removeEventListener("touchstart", this.touchstart);
    document.removeEventListener("touchend", this.touchend);
  },
  methods: {
    touchstart(event) {
      this.touchstartX = event.changedTouches[0].screenX;
      this.touchstartY = event.changedTouches[0].screenY;
    },
    touchend(event) {
      this.touchendX = event.changedTouches[0].screenX;
      this.touchendY = event.changedTouches[0].screenY;
      this.handleGesture();
    },
    handleGesture() {
      if (
        this.touchendX < this.touchstartX &&
        Math.abs(this.touchendX - this.touchstartX) > this.adjfactor &&
        Math.abs(this.touchendY - this.touchstartY) <
        Math.abs(this.touchendX - this.touchstartX)
      ) {
        // console.log('De derefha a izquierda');
        if (
          document.getElementById("offcanvasExample").classList.contains("show")
        ) {
          this.canvas.hide();
        }
      }

      if (
        this.touchendX > this.touchstartX &&
        Math.abs(this.touchendX - this.touchstartX) > this.adjfactor &&
        Math.abs(this.touchendY - this.touchstartY) <
        Math.abs(this.touchendX - this.touchstartX)
      ) {
        // console.log('De izquierda a derecha');
        if (
          !document
            .getElementById("offcanvasExample")
            .classList.contains("show")
        ) {
          this.canvas.show();
        }
        // document.getElementById('offcanvasId').click();
      }
    },
  },
};
</script>

<style>
.subcategories {
  width: 400px;
  height: 100%;
  position: fixed;
  top: v-bind(alturaHeader) !important;
  left: -200%;
  background-color: #f8f9fa;

}

.selected {
  background-color: rgb(223, 226, 230) !important;
}


.oculto {
  visibility: hidden !important;
}

.collapse .li-subCategories:hover {
  background-color: rgb(223, 226, 230) !important;
}

.subcategories.active {
  transition: left 0.3s ease-in-out;
  left: 0;
}

.li-sub-md:hover {
  text-decoration: underline !important;
}

.subcategories.inactive {
  transition: left 0.3s ease-in-out !important;
  left: -200% !important;
}</style>