<template>
  <div v-if="type === 'F'" class="row mt-2 border-2" id="cont_categorias">
    <div class="col">
      <div class="row justify-content-center align-items-center">
        <div class="col-auto" v-for="categoria in categorias" :key="categoria.codigoGrupo"
          v-bind:id="'categoria_' + categoria.codigoGrupo">
          <div class="card bg-transparent text-white border-0 mt-1" v-if="categoria.imagenGrupo">
            <a href="javascript:void(0);" @click="sendCatalogGrupo(categoria)">
              <img :src="categoria.path + categoria.imagenGrupo" class="card-img" :alt="categoria.nombreGrupo"
                style="max-height: 300px" />
              <div class="card-img-overlay card-hover">
                <div class="bg-semi-black-transparent">
                  <h5 class="card-title text-center nav-link text-white"
                    :class="issuserParam.fuenteSubtitulo && issuserParam.fuenteSubtitulo != '-1' ? 'subtitle-client' : ''">
                    {{ categoria.nombreGrupo }}
                  </h5>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="type === 'C'" class="row mt-3 border-2">
    <Carousel :settings="settings" :items-to-show="2.5" :breakpoints="breakpoints" :wrap-around="true"
      id="categoriasCarousel">

      <Slide v-for="categoria in categorias" :key="categoria.codigoGrupo" class="categorias_items mx-2 p-4"
        v-bind:id="'categoria_' + categoria.codigoGrupo">
        <div class="card bg-transparent text-white border-0 mt-1" v-if="categoria.imagenGrupo"
          style="min-width: 95%!important;">
          <a href="javascript:void(0);" @click="sendCatalogGrupo(categoria)" class="position-relative">
            <img :src="categoria.path + categoria.imagenGrupo" class="card-img" :alt="categoria.nombreGrupo"
              style="max-height: 300px" />
            <div
              class="bg-categorias position-absolute bottom-0 start-0 end-0 d-flex align-items-center justify-content-center" style="height: 40px;">
              <h5 class="card-title text-center text-categorias-client mb-0 p-0 fs py-1"
                
                :class="issuserParam.fuenteSubtitulo && issuserParam.fuenteSubtitulo != '-1' ? 'subtitle-client' : ''">
                {{ categoria.nombreGrupo }}
              </h5>
            </div>
          </a>
        </div>
      </Slide>

      <template #addons>
        <Navigation />
      </template>
    </Carousel>
  </div>
</template>

<script>
import { inject, ref/* , onMounted, nextTick */ } from "vue";
import router from "@/router";
import { Carousel, Navigation, Slide } from "vue3-carousel";
export default {
  components: {
    Carousel,
    Slide,
    Navigation,
  }, props: {
    type: String,
    configCatsImagenes: {
      type: Object,
      default: () => ({
        lg: 6
      })
    }
  }, data() {
    return {
      fixed: "",
      drop: "",
      sizeIcon: "",
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
      }
    };
  },
  computed: {
    breakpoints() {
      return {
        500: {
          itemsToShow: 1,
          snapAlign: "start",
        },
        765: {
          itemsToShow: parseFloat(this.configCatsImagenes.md)-2,
          snapAlign: "start",
        },1000: {
          ///siempre hay que parsear  porque sino el carousel no da la vuelta completa
          itemsToShow: parseFloat(this.configCatsImagenes.lg) < 8 && parseFloat(this.configCatsImagenes.lg) >= 4 ? parseFloat(this.configCatsImagenes.lg) -3 : parseFloat(this.configCatsImagenes.lg) - 4,
          snapAlign: "start",
        },
        1200: {
          ///siempre hay que parsear  porque sino el carousel no da la vuelta completa
          itemsToShow: parseFloat(this.configCatsImagenes.lg) < 8 && parseFloat(this.configCatsImagenes.lg)>= 4 ? parseFloat(this.configCatsImagenes.lg) -2 : parseFloat(this.configCatsImagenes.lg) - 3,
          snapAlign: "start",
        },
        1400: {
          ///siempre hay que parsear  porque sino el carousel no da la vuelta completa
          itemsToShow: parseFloat(this.configCatsImagenes.lg)-2,
          snapAlign: "start",
        },
        1600: {
          ///siempre hay que parsear  porque sino el carousel no da la vuelta completa
          itemsToShow: parseFloat(this.configCatsImagenes.lg),
          snapAlign: "start",
        },
      };
    }
  },
  setup(props) {
    const categorias = inject("categorias");
    const tieneGruposCategorias = inject("tieneGruposCategorias");
    const inicioHash = inject("inicioHash");
    const categoriaSelected = inject("categoriaSelected");
    const navCodigoColorIcono = inject("navCodigoColorIcono");
    const categoriasColorClient = inject("categoriasColorClient");
    const colorFlechas = ref(props.configCatsImagenes.colorFlechas);
    const issuserParam = inject("issuserParam");

    const sendCatalogGrupo = (categoria) => {
      router.push({ name: "Catalog", params: { categoria: categoria.nombreGrupo.toLowerCase() } });
      inicioHash.verProductos.class = "active";
      inicioHash.verFavoritos.class = "";
      categoriaSelected.value = categoria;
    };

/*     const ajustarFontSize =(h5Element)=> {
      let fontSize = window.getComputedStyle(h5Element, null).getPropertyValue('font-size');
      fontSize = parseFloat(fontSize);
      const container = h5Element.parentElement;

      while (h5Element.scrollWidth > container.clientWidth) {
        fontSize -= 0.5;  // Reducimos el tamaño de la fuente poco a poco
        h5Element.style.fontSize = fontSize + 'px';
      }
    }

    const ajustarTodosFontSize = () => {
      // Obtener todos los elementos h5 dentro del carrusel
      const h5Elements = document.querySelectorAll('.bg-categorias h5');

      h5Elements.forEach(ajustarFontSize);
    }

    onMounted(() => {
      nextTick(() => {
        ajustarTodosFontSize();
      });

      window.addEventListener('resize', ajustarTodosFontSize);
      return () => {
        window.removeEventListener('resize', ajustarTodosFontSize);
      };
    });
 */
    return {
      navCodigoColorIcono,
      categorias,
      tieneGruposCategorias,
      sendCatalogGrupo,
      categoriasColorClient,
      colorFlechas,
      issuserParam
    };
  }
};
</script>

<style type="scss">
@media(max-width: 576px) {
  .categoria_1 {
    padding-top: 5%;
  }

  #cont_categorias {
    margin-top: 0% !important;
  }
}

#categoriasCarousel>.carousel__prev,
#categoriasCarousel>.carousel__next {
  background-color: transparent !important;

}

#categoriasCarousel>.carousel__prev .carousel__icon,
#categoriasCarousel>.carousel__next .carousel__icon {
  color: v-bind(colorFlechas) !important;
}

#categoriasCarousel .bg-categorias {
  background-color: v-bind(categoriasColorClient) !important;
}

.fs {
  font-size: 14px;
}

@media(max-width:1139px) {
  .fs {
    font-size: 11px !important;
  }
}

.categorias_items:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 2px solid rgba(0, 0, 0, .125) !important;
}
</style>