<template>
  <loading-data v-if="loadingData" tipo="T" msg="Cargando medios de pago" />
  <div class="container padding pb-md-5 mt-md-3 mt-lg-3 pt-md-5 pt-lg-5 px-0">

    <div class="row d-flex justify-content-center">
      <div class="col-12 mt-2 mt-md-2 mt-lg-2 px-4 text-center border-bottom">
        <h2 :class="issuserParam.fuenteTitulo && issuserParam.fuenteTitulo != '-1' ? 'title-client' : ''">Confirmar Compra
        </h2>
      </div>

      <div class="col-12 col-sm-12 col-md-12 col-lg-6 mt-2 pb-3" :class="pasos != 2 ? 'd-block' : 'd-none'">
        <div class="card border-0">
          <h5 class="px-3 px-md-3 px-lg-3">Datos del cliente:</h5>
          <div class="card-body pb-0">
            <div class="row">
              <div class="col">
                <label for="tipoDocumentoReceptor" class="form-label mb-0">Tipo Cliente <span
                    class="required">*</span></label>
                <select class="form-select" v-model="cliente.tipoDocumentoReceptor">
                  <option value="4">Otros - Sin Especificar</option>
                  <option value="2">RUT - Empresas Uruguayas</option>
                  <option value="3">CI - Cedula Uruguaya</option>
                </select>
              </div>
              <div class="col">
                <label for="documentoReceptor" class="form-label mb-0">Documento <span class="required">*</span></label>
                <input type="text" class="form-control" maxlength="20" placeholder="Documento"
                  v-model="cliente.documentoReceptor" />
              </div>
            </div>
            <div class="row mt-1">
              <div class="col">
                <label for="nombre" class="form-label mb-0">Nombre <span class="required">*</span></label>
                <input type="text" class="form-control" maxlength="50" placeholder="Nombre" v-model="cliente.nombre" />
              </div>
            </div>
            <div class="row mt-1">
              <div class="col">
                <label for="direccion" class="form-label mb-0">Dirección <span class="required">*</span></label>
                <input type="text" class="form-control" maxlength="50" placeholder="Dirección"
                  v-model="cliente.direccion" />
              </div>
            </div>
            <div class="row mt-1">
              <div class="col">
                <label for="ciudad" class="form-label mb-0">Ciudad <span class="required">*</span></label>
                <input type="text" class="form-control" maxlength="50" placeholder="Ciudad" v-model="cliente.ciudad" />
              </div>
              <div class="col">
                <label for="departamento" class="form-label mb-0">Departamento <span class="required">*</span></label>
                <input type="text" class="form-control" maxlength="50" placeholder="Departamento"
                  v-model="cliente.departamento" />
              </div>
            </div>
            <div class="row mt-1">
              <div class="col">
                <label for="email" class="form-label mb-0">Email de Contacto <span class="required">*</span></label>
                <input type="email" class="form-control" maxlength="50" placeholder="Email" v-model="cliente.email" />
              </div>
              <div class="col">
                <label for="telefono" class="form-label mb-0">Teléfono <span class="required">*</span></label>
                <input type="text" class="form-control" maxlength="50" placeholder="Teléfono"
                  v-model="cliente.telefono" />
              </div>
            </div>
            <div class="row mt-3">
              <div class="col">
                <div class="form-floating">
                  <textarea v-model="cliente.comentario" class="form-control" maxlength="100" style="height: 100px"
                    placeholder="Comentarios adicionales."></textarea>
                  <label for="comentarioPedido">Comentarios</label>
                </div>
              </div>
            </div>
            <div v-if="terminosYCondiciones.length > 0" class="row py-2">
              <div class="col">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" checked>
                  <label class="form-check-label" for="flexCheckDefault">
                    Acepto los <a role="button" class="link text-dark" @click="validarTerminosCondiciones">términos y
                      condiciones</a>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="col-12 col-sm-12 col-md-12 col-lg-6 mt-2 pb-3" :class="pasos != 1 ? 'd-block' : 'd-none'">
        <div class="card border-0">
          <ul class="list-group list-group-flush rounded-2" v-if="issuserParam.pedidossinmediodepago != '2'">
            <h6 class="px-4 px-md-0 px-lg-0">Generar Pedido:</h6>
            <li class="list-group-item border">
              <div class="row pb-2">
                <div class="col-12">
                  <BuyFE @selection="handleSelection" ref="buyFeRef" />
                </div>
                <div v-if="issuserParam.condicionesPago != 'N' && buyFeSelected">
                  <div class="col-6 col-lg-5 col-md-5 col-sm-4">
                    <h6>Condición de pago:</h6>
                  </div>
                  <div class="col-6">
                    <select class="form-select" id="selectCondicionPago" aria-label="Pay express Cuotas"
                      v-model="condicionPagoSelected">
                      <option v-for="condicion in condicionesPago" :key="condicion" :value="condicion"
                        :selected="condicion === 0" :disabled="condicion === 0">
                        {{ condicion === 0 ? "Elija una condicion de pago" : condicion }}
                      </option>
                    </select>
                  </div>
                  <div v-if="condicionPagoSelected === 'cuenta corriente'" class="col-12 pt-3">
                    <h6>Ingrese nombre del titular de la cuenta:</h6>
                    <input type="text" class="form-control" placeholder="Nombre completo..." v-model="titularCuenta" />
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <h6 class="px-4 px-md-0 px-lg-0">Pagar con:</h6>
          <ul class="list-group list-group-flush rounded-2" v-if="sellosPayExpress.length || issuserParam.emiteCompraSN === 'S'">

            
            <li class="list-group-item border" v-if="issuserParam.emiteCompraSN === 'S'">
              <BuyMercadoPago />
            </li>

            <li class="list-group-item border" v-for="payExpress in sellosPayExpress" :key="payExpress.tipo">
              <div class="form-check">
                <input class="form-check-input radio-checkout" v-model="payExpressSelected" :value="payExpress"
                  type="radio" name="radioMediopago" :id="[`radioMedioPago_${payExpress.sello}`]" />
                <button type="button" class="
                  btn btn-outline-light
                  btnPagarPayExpressPrevia
                  border-0" tipo="" pasarela="" cuotas="">
                  <img class="img-fluid sellos" :src="payExpress.logo" style="max-width: 50px!important;"
                    :alt="payExpress.sello" />
                </button>
              </div>
            </li>

            <div class="d-grid col-6 col-sm-3" v-for="siemprePago in sellosSiemprePago" :key="siemprePago.idSello">
              <button type="button" class="btn btn-outline-light btnFacturarPagoWeb border-0"
                @click="loadSiemprePago(siemprePago)">
                <img class="img-fluid w-100" :src="siemprePago.logo" :alt="siemprePago.sello" />
              </button>
            </div>
          </ul>
          <ul class="list-group list-group-flush rounded-2" v-if="descuentos.length && descuentos.length > 0">
            <!---ACA SE DESPLIEGAN LOS BANCOS O TARJETAS CON DESCUENTOS-->
            <li class="list-group-item border" v-for="descuento in descuentos" :key="descuento.sello">
              <div class="row px-2">
                <div class="form-check d-flex precio-descuento col-5 col-md-7 col-lg-7">
                  <input class="form-check-input radio-checkout" v-model="payExpressSelected" :value="descuento"
                    type="radio" name="radioMediopago" :id="[`radioMedioPago_${descuento.banco + descuento.sello}`]" />
                  <h2 class="m-0 mx-lg-1">{{ descuento.descBanco + descuento.descComercio }}</h2><span>%</span>

                  <button type="button" class="
                        p-0
                        btn btn-outline-light
                        btnPagarPayExpressPrevia
                        border-0" tipo="" pasarela="" cuotas="">
                    <img class="img-fluid sellos" :src="'/img/mediospago/texto-' + descuento.logoBanco"
                      style="max-width: 70px!important;" :alt="descuento.banco" />
                  </button>
                </div>
                <div class="col-7 col-md-5 col-lg-5">
                  <label class="form-label text-muted">{{ descuento.sello.toUpperCase() + " " +
                    descuento.banco.toUpperCase() }}</label>
                </div>
              </div>
              <!---EN ESTE DIV SE INGRESA EL BIN DE LA TARJETA QUE TIENE DESCUENTO -->
              <div class="col-12 pt-1"
                :class="payExpressSelected.banco && payExpressSelected.sello === descuento.sello ? 'd-block' : 'd-none'">
                <div class="bg-dircret rounded-pill py-2 px-2">
                  <input type="text" class="form-control d-block" maxlength="50" v-model="binDescuento.bin"
                    @keyup="validarBin" />
                </div>
                <label class="form-label" :class="binDescuento.claseMensaje ? binDescuento.claseMensaje : 'text-muted'">{{
                  binDescuento.mensaje ?
                  binDescuento.mensaje : "Ingrese los primeros 6 dígitos de su tarjeta para validar su cuenta santander"
                }}</label>
              </div>
            </li>
          </ul>
          <div class="row pt-1 justify-content-end">

            <!---EN ESTE DIV SE INGRESA LAS CUOTAS DE LA TARJETA -->
            <div class="col-6 mx-2">
              <div class="row py-2 d-flex justify-content-end"
                :class="Object.keys(payExpressSelected).length > 0 ? 'd-block' : 'd-none'">
                <div class="col-5 col-sm-5  col-md-3 col-lg-3">
                  <h6 class="text-right">cuotas:</h6>
                </div>
                <div class="col-7 col-sm-7 col-md-6 col-lg-6">
                  <select class="form-select" id="selectCuotasPayExpress" aria-label="Pay express Cuotas"
                    v-model="cuotaSelected">
                    <option v-for="cuota in cuotas" :key="cuota" :value="cuota">
                      {{ cuota }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-12 col-lg-6 pb-3">
        <h5 class="px-4 px-md-0 px-lg-0 pt-2">Productos:</h5>
        <ul class="list-group list-group-flush" id="ulTotales">
          <li class="list-group-item px-4 px-md-0 px-lg-0 border-bottom" v-for="product in products"
            :key="product.codigo">
            <div class="row">
              <div class="col px-0">
                <img :src="[
                  product.imagen.includes('no-img')
                    ? '/img/new-products/no-img.jpg'
                    : product.ruta + product.imagen,
                ]" class="img-fluid rounded-start img-cart img-card" :alt="product.imagen" style="
                            object-fit: scale-down;
                            max-width: 60px;
                            height: auto;
                          " />
              </div>

              <div class="col-7">{{ capitalizarPalabras(product.nombreProducto) }}
                <span class="fw-bold">X {{ product.cantidad }}</span>
              </div>
              <div class="col px-0 text-end">
                {{ formatMoney(issuserParam.monedalp) }}
                {{ formatPrice(product.precioVenta, decimal) }}
              </div>
            </div>
          </li>
          <li class="list-group-item" v-if="adjust10">
            <div class="row">
              <div class="col-8">Ajuste Totales x 1</div>
              <div class="col text-end">
                $ {{ formatPrice(adjust10, decimal) }}
              </div>
            </div>
          </li>
        </ul>
        <ul class="list-group list-group-flush pt-2">
          <li class="list-group-item border-0 pt-0 px-4 px-md-0 px-lg-0">
            <div v-if="issuserParam.datosBancarios" class="row">
              <div class="col-12 px-0">
                <h6>Datos Bancarios: </h6>
              </div>
              <div class="col-12 px-0">
                <p>{{ issuserParam.datosBancarios }}</p>
              </div>
            </div>
          </li>
          <li id="envio" class="list-group-item border-bottom-envio px-4 px-md-0 px-lg-0">
            <div class="row">
              <div class="col-12 px-0">
                <h6>Envío
                  <fa icon="truck" />
                </h6>
              </div>
              <div class="col-12 px-0">           
                <div class="row" v-for="(precio, index) in precios" :key="precio.idPrecio">
                  <div class="col-8">
                    <div class="form-check">
                      <input class="form-check-input radio-checkout" type="radio" name="radioenvio"
                        :id="[`radio_${index}`]" :value="precio" v-model="precioEnvio" />
                      <label class="form-check-label" :for="[`radio_${index}`]">
                        {{ precio.descripcion }}
                      </label>
                    </div>
                  </div>
                  <div class="col text-end">
                    <fa icon="truck" />
                    {{
                      precio.precio
                      ? `${formatMoney(
                        issuserParam.monedalp
                      )} ${formatPrice(precio.precio, decimal)}`
                      : "Gratis"
                    }}
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>

      </div>
      <div class="row bg-dircret rounded-2">
        <div class="col p-0">
          <h5 class="px-md-4 px-lg-4">TOTAL:</h5>
        </div>
        <div class="col text-end px-md-4 px-lg-4">
          <h4 class="fw-bold" id="totalPedidovisible"
            :class="binDescuento.clasePrecioAnterior ? binDescuento.clasePrecioAnterior : ''">
            {{ formatMoney(issuserParam.monedalp) }}
            {{ formatPrice(computedTotal, decimal) }}
          </h4>
        </div>
        <div class="col text-end px-md-4 px-lg-4" v-if="binDescuento.precioDescuento">
          <h4 class="fw-bold" id="totalDescuentovisible"
            :class="binDescuento.clasePrecioDescuento ? binDescuento.clasePrecioDescuento : ''">
            {{ formatMoney(issuserParam.monedalp) }}
            {{ formatPrice(computedTotalDesc, decimal) }}
          </h4>
        </div>
      </div>
    </div>
    <div class="row pt-2">
      <div class="card-body mb-5 pt-0 pt-md-3 pt-lg-3 border-top">
        <div class="row mb-5 justify-content-center">
          <div class="d-grid col-12 col-sm-6 col-md-4 col-lg-4 text-center mt-1">
            <button class="btn btn-client-invertido btn-client-border" @click="continuarComprando">
              <fa icon="cart-arrow-down" /> {{ pasos != 2 ? "Continuar comprando" : "Volver" }}
            </button>
          </div>
          <div class="d-grid col-12 col-sm-6 col-md-4 col-lg-4 text-center mt-1">
            <button class="btn btn-client" @click="validarmediopago()">
              <fa icon="shopping-cart" />{{ pasos != 2 ? "Continuar" : "Confirmar compra" }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <SiemprePago ref="childSiemprePagoRef" />
    <PayExpress ref="childComponentRef" />
  </div>
</template>

<script>
import { inject, reactive, ref, watch, computed, provide, onMounted, onUnmounted } from "vue";
import {
  validarCedula,
  validarRuc,
  validateSendMails,
  validarInputNombreCheckout,
  validarInputsCheckout,
  validarTelefonoCheckout,
  formatPrice,
  capitalizarPalabras
} from "@/composable/functions";
import Swal from "sweetalert2";
import router from "@/router";

import {
  calcSalePrice,
  calcSubTotal,
  adjust10th,
} from "@/composable/functions";

import BuyMercadoPago from "@/components/BuyMercadoPago.vue";
import BuyFE from "@/components/BuyFE.vue";
import PayExpress from "@/components/PayExpress.vue";
import SiemprePago from "@/components/SiemprePago.vue";
import LoadingData from "@/components/LoadingData.vue";

export default {
  components: {
    BuyMercadoPago,
    BuyFE,
    PayExpress,
    SiemprePago,
    LoadingData
  },
  setup() {
    const $axios = inject("$axios");
    const productsComplete = inject("productsComplete");
    const postApiGeneric = inject("postApiGeneric");
    const pedidoFE = inject("pedidoFE");
    const decimal = inject("decimal");
    const cart = inject("cart");
    const getApiGeneric = inject("getApiGeneric");
    const issuserParam = inject("issuserParam");
    const products = ref([]);
    const total = ref(0);
    const adjust10 = ref(0);
    const precios = ref([]);
    const precioEnvio = ref({});
    const computedTotal = computed(() =>
      parseFloat(total.value + precioEnvio.value.precio)
    );
    const sellosPayExpress = ref([]);
    const sellosSiemprePago = ref([]);
    const payExpressSelected = ref({});
    const siemprePagoSelected = ref({});
    const loadingData = ref(true);
    const styleClass = inject("styleClass");
    const terminosYCondiciones = inject("terminosYCondiciones");
    const cuotaSelected = ref(1);
    const condicionesPago = [0, 'contado', 'cuenta corriente', 'pos'];
    const condicionPagoSelected = ref(0);
    const titularCuenta = ref("");
    const buyFeRef = ref();

    const descuentos = inject("descuentos");
    const binDescuento = ref({
      mensaje: "Ingrese los primeros 6 dígitos de su tarjeta para validar su cuenta santander",
      claseMensaje: "text-muted"
    });
    const bines = ref([]);
    const productsDescuento = ref([]);

    const totalDesc = ref(0);
    const computedTotalDesc = computed(() => {
      if (binDescuento.value.precioDescuento && binDescuento.value.precioDescuento > 0) {
        return binDescuento.value.precioDescuento + parseFloat(precioEnvio.value.precio);
      }
      return 0;
    })

    const pasos = ref(1);
    const pedidosCheck = ref();
    const buyFeSelected = ref(false);
    const childComponentRef = ref();

    let cliente = reactive({
      tipoDocumentoReceptor: "4",
      documentoReceptor: "",
      nombre: "",
      direccion: "",
      ciudad: "",
      departamento: "",
      email: "",
      telefono: "",
      comentario: "",
    });


    const cuotas = computed(() => {
      let arrayCuotas = [1];
      if (!payExpressSelected.value.cuotas) {
       // cuotaSelected.value = 1
        return arrayCuotas;
      }
      for (let i = 1; i <= payExpressSelected.value.cuotas; i++) {
        arrayCuotas.push(i);
      }
      return arrayCuotas;
    });

    const instanceCart = () => {
      if(cart.value.length <= 0){
        return;
      }
      let url = `productos/cart`;
      let params = { idDeposito: parseInt(issuserParam.value.idDeposito), idsProductos: cart.value }
      postApiGeneric(url, params, (response) => {
        if (response.cod_respuesta == "00") {
          products.value = response.retorno;
          products.value.forEach((prod) => {
            total.value += parseFloat(prod.subTotal);
          });
          instanceTrack();
          setearDiv();
          loadingData.value = false;
        }
      });
    }

    const instancePrecios = () => {
      getApiGeneric("precios", null, (price) => {
        precios.value = price.retorno;
        precioEnvio.value = precios.value[0];
      });
    };

    const setearMicrodatosCheckout = () => {
      //CHECKEO QUE NO HAYA MICRDATOS INSERTADOS
      const scripts = Array.from(document.head.getElementsByTagName('script'));
      scripts.forEach(script => {
        if (script.type === "application/ld+json") {
          document.head.removeChild(script);
        }
      });

      let arrayProductosMicroData = [];

      products.value.forEach(
        (pr) => {
          let producto = {
            '@type': 'Product',
            'category': "1604",
            'name': pr.nombreProducto,
            'description': pr.descripcion,
            'url': pr.google_category ? pr.google_category : "1604",
            'image': pr.ruta + pr.imagen,
            'offers': {
              '@type': 'Offer',
              'price': pr.precioUnitario,
              'priceCurrency': 'UYU',
              'availability': pr.stock > 0 ? 'https://schema.org/InStock' : "https://schema.org/OutOfStock",
            },
            'itemCondition': 'https://schema.org/NewCondition',
            'productID': pr.codigo,
            "sku": pr.codigo
          };
          arrayProductosMicroData.push(producto);
        }
      )
      let jsonLd = {
        '@context': 'https://schema.org/',
        '@type': 'ItemList',
        'name': 'Catálogo de checkout',
        'description': 'Lista de productos del checkout',
        'itemListElement': arrayProductosMicroData
      }

      let script = document.createElement('script');
      script.type = "application/ld+json";
      script.textContent = JSON.stringify(jsonLd);
      //seteo los microdatos
      document.head.appendChild(script);
    }

    ///Esta funcion genera la funcion con los parametros a ejecutar para activar el evento addToCart
    const instanceTrack = () => {
      if (issuserParam.value.generaCatalogo != undefined && issuserParam.value.generaCatalogo === "S") {
        let contents = [];
        cart.value.forEach(
          (product) => {
            let prod = { id: product.id, quantity: product.cantidad };
            contents.push(prod);
          }
        );
        setearMicrodatosCheckout();
        let objTrack = { value: total.value, currency: "UYU", contents: contents, content_type: 'product' };
        let script = "fbq('track', 'InitiateCheckout'," + JSON.stringify(objTrack) + ");";
        eval(script);
      }
    };


    const instancePasarelas = () => {
      getApiGeneric("pasarelas/payExpress", null, (pasarela) => {
        sellosPayExpress.value = pasarela.retorno;
      });
      getApiGeneric("pasarelas/siemprePago", null, (pasarela) => {
        sellosSiemprePago.value = pasarela.retorno;
      });
    };

    const obtenerBines = () => {
      if (issuserParam.value.planDescuentoBin && issuserParam.value.planDescuentoBin === "S") {
        $axios
          .get(`/descuentos/bines`)
          .then((result) => {
            if (result.data.cod_respuesta == "00") {
              bines.value = result.data.retorno;
            }
          });
      }
    }

    const instanceAll = () => {
      instanceCart();
      instancePrecios();
      instancePasarelas();
      obtenerBines();
      instanceTrack();
      loadingData.value = false;
    };

    if (issuserParam.value.idLP) {
      instanceAll();
    }

    watch(
      () => issuserParam.value,
      (newParams) => {
        if (!newParams) return;
        instanceAll();
      }
    );
    const calcTotal = () => {
      total.value = products.value.reduce((acc, curr) => {
        return acc + parseFloat(curr.subTotal);
      }, 0);
      return total.value;
    };

    const validarCondicionPago = () => {
      if (document.getElementById('radioMedioPago_FE').checked && condicionPagoSelected.value === 0) {
        Swal.fire("Debe ingresar una condicion de pago.", "", "warning");
        return false;
      }
      if (condicionPagoSelected.value === 'cuenta corriente' && titularCuenta.value === "") {
        Swal.fire("Debe ingresar el nombre del titular de la cuenta.", "", "warning");
        return false;
      }
      return true;
    }

    const desplegarSwalValidacion = (titulo, alerta) => {
      Swal.fire({
        title: titulo,
        icon: alerta,
        confirmButtonColor: styleClass.value
      });
    }


    const validarCliente = () => {
      if(cliente.documentoReceptor.trim() == "" || cliente.documentoReceptor.trim().length <= 3){
        desplegarSwalValidacion("Documento incorrecto", "warning");
        return false;
      }
      if (
        cliente.tipoDocumentoReceptor === "3" &&
        cliente.documentoReceptor.trim() === ""
      ) {
        desplegarSwalValidacion("Debe ingresar cédula", "warning");
        return false;
      }
      if (
        cliente.tipoDocumentoReceptor === "3" &&
        !validarCedula(cliente.documentoReceptor.trim())
      ) {
        desplegarSwalValidacion(
          "La cédula ingresada no es correcta, la misma no debe contener puntos ni guiones.",
          "warning"
        );
        return false;
      }
      if (
        cliente.tipoDocumentoReceptor === "2" &&
        cliente.documentoReceptor.trim() === ""
      ) {
        desplegarSwalValidacion("Debe ingresar RUT.", "warning");
        return false;
      }
      if (
        cliente.tipoDocumentoReceptor === "2" &&
        validarRuc(cliente.documentoReceptor.trim()) === false
      ) {
        desplegarSwalValidacion(
          "El RUT ingresado no es correcto, el mismo no debe contener puntos ni guiones.",
          "warning"
        );
        return false;
      }
      if (cliente.nombre.trim() === "") {
        desplegarSwalValidacion("Debe ingresar nombre.", "warning");
        return false;
      }
      if (!validarInputNombreCheckout(cliente.nombre.trim())) {
        desplegarSwalValidacion("El nombre no puede contener caracteres especiales ni numeros.", "warning");
        return false;
      }

      if (cliente.direccion.trim() === "") {
        desplegarSwalValidacion("Debe ingresar dirección.", "warning");
        return false;
      }
      if (!validarInputsCheckout(cliente.direccion.trim())) {
        desplegarSwalValidacion("Debe ingresar dirección válida.", "warning");
        return false;
      }

      if (cliente.ciudad.trim() === "") {
        desplegarSwalValidacion("Debe ingresar ciudad.", "warning");
        return false;
      }

      if (!validarInputsCheckout(cliente.ciudad.trim())) {
        desplegarSwalValidacion("Debe ingresar ciudad válida.", "warning");
        return false;
      }
      if (
        cliente.email.trim() === "" ||
        !validateSendMails(cliente.email.trim()).valid
      ) {
        desplegarSwalValidacion("Debe ingresar email o email no es correcto.", "warning");
        return false;
      }
      if (cliente.telefono.trim() === "") {
        desplegarSwalValidacion("Debe ingresar teléfono.", "warning");
        return false;
      }
      if (!validarTelefonoCheckout(cliente.telefono.trim())) {
        desplegarSwalValidacion("Debe ingresar un teléfono válido y sin espacios", "warning");
        return false;
      }
      if (cliente.departamento.trim() === "") {
        desplegarSwalValidacion("Debe ingresar Departamento.", "warning");
        return false;
      }
      if (!validarInputsCheckout(cliente.departamento.trim())) {
        desplegarSwalValidacion("Debe ingresar Departamento correcto.", "warning");
        return false;
      }
      if (issuserParam.value.pedidossinmediodepago != '2' && issuserParam.value.condicionesPago === 'S') {
        return validarCondicionPago();
      }
      return true;
    };


    const validarTerminosCondiciones = () => {
      Swal.fire({
        title: "Términos y condiciones",
        html: generarHTMLTerminosYCodiciones(),
        confirmButtonClass: 'boton-oculto',
        showCloseButton: true,
        customClass: {
          title: 'titulo-terminos',
          content: 'contenido-terminos',
          closeButton: 'boton-cerrar'
        },
        scrollbarPadding: true
      });
    }

    const generarHTMLTerminosYCodiciones = () => {
      let htmlStr = "";
      terminosYCondiciones.value.forEach(
        (termino) => {

          htmlStr += `<h5 class="pt-2 pb-1 text-start">` + termino.titulo + `</h5>`;
          htmlStr += `<p class="text-start">` + termino.descripcion + `</p>`;
        }
      )
      return htmlStr;
    }

    const cambiarPaso = (paso) => {
      loadingData.value = true;
      setTimeout(() => {
        pasos.value = paso;
        loadingData.value = false;
        window.scrollTo(0, 0);
      }, 200);
    }

    const validarMedioPagoDescuento = () => {

      if (!payExpressSelected.value.bin) {
        Swal.fire("Debe ingresar los primeros 6 digitos de su tarjeta para validar su cuenta antes de continuar.", "", "warning");
        return false;
      }
      if (!binDescuento.value.precioDescuento) {
        Swal.fire("Los primeros 6 digitos de su tarjeta deben ser validos para que se le aplique el descuento.", "", "warning");
        return false;
      }
      return true;
    }

    const validarmediopago = () => {
      if (!validarCliente()) {
        return;
      }

      if (pasos.value === 1) {
        cambiarPaso(2);
        return;
      }

      if (computedTotal.value <= 0) {
        Swal.fire("Agregue un articulo al carrito para completar la compra.", "", "warning");
        return;
      }
      if (document.getElementById('radioMedioPago_MercadoPago')?.checked) {
        facturarMercadoPago();
        return;
      }
      if (buyFeSelected.value) {
        generarPedido();
        return;
      }

      if (!payExpressSelected.value.cuotas) {
        Swal.fire("Seleccione Medio de pago", "", "warning");
        return;
      }

      if (payExpressSelected.value.banco && !validarMedioPagoDescuento()) {
        return;
      }


      if (!cuotaSelected.value) {
        Swal.fire("Debe seleccionar una cuota.", "", "warning");
        return;
      }
      productsComplete.value = products.value;
      loadPayExpress();
    }

    const generarPedido = () => {
      let carritoDD = [
        { variable: "moneda", variableValor: issuserParam.value.monedalp },
        { variable: "condicionPago", variableValor: condicionPagoSelected.value }
      ];
      if (condicionPagoSelected.value === 'cuenta corriente') {
        carritoDD.push({ variable: "titularCuenta", variableValor: titularCuenta.value });
      }
      var objEnvioPedido = {};
      objEnvioPedido.nombrePedido = cliente.nombre;
      objEnvioPedido.direccionPedido = cliente.direccion;
      objEnvioPedido.ciudadPedido = cliente.ciudad;
      objEnvioPedido.emailPedido = cliente.email;
      objEnvioPedido.telefonoPedido = cliente.telefono;
      objEnvioPedido.comentarioPedido = cliente.comentario;
      objEnvioPedido.documentoReceptor = cliente.documentoReceptor;
      objEnvioPedido.departamentoPedido = cliente.departamento;
      objEnvioPedido.tipoDocumento = cliente.tipoDocumentoReceptor;
      objEnvioPedido.idPrecioEnvio = precioEnvio.value.idPrecio;
      objEnvioPedido.carritoDD = carritoDD;
      objEnvioPedido.total = computedTotal.value;
      objEnvioPedido.totalSinCambios = total.value;
      objEnvioPedido.listaCarritoCompras = products.value;
      objEnvioPedido.nombreComercialEmisor = issuserParam.value.nombreComercial;
      objEnvioPedido.logoEmisor = issuserParam.value.rutaLogo + issuserParam.value.logo;

      postApiGeneric(
        "/pedidos/generar",
        objEnvioPedido,
        (retorno) => {
          pedidoFE.value = retorno;
          if (retorno.cod_respuesta === "00") {
            router.push({ name: "FeComplete" });
            return retorno;
          } else {
            Swal.fire("Algo salió mal, su pedido no se pudo generar.", "", "warning");
          }
        }
      );
    };

    const facturarMercadoPago = () => {
      if (!validarCliente()) {
        return;
      }
      var carritoDD = [
        { variable: "moneda", variableValor: issuserParam.value.monedalp },
      ];
      var objEnvioPedido = {};
      objEnvioPedido.nombrePedido = cliente.nombre;
      objEnvioPedido.direccionPedido = cliente.direccion;
      objEnvioPedido.ciudadPedido = cliente.ciudad;
      objEnvioPedido.emailPedido = cliente.email;
      objEnvioPedido.telefonoPedido = cliente.telefono;
      objEnvioPedido.comentarioPedido = cliente.comentario;
      objEnvioPedido.documentoReceptor = cliente.documentoReceptor;
      objEnvioPedido.departamentoPedido = cliente.departamento;
      objEnvioPedido.tipoDocumento = cliente.tipoDocumentoReceptor;
      objEnvioPedido.idPrecioEnvio = precioEnvio.value.idPrecio;
      objEnvioPedido.carritoDD = carritoDD;
      objEnvioPedido.total = computedTotal.value;
      objEnvioPedido.totalSinCambios = total.value;

      Swal.fire({
        title: "Enviando a Mercado Pago",
        html: "Esto puede tardar unos segundos",
        didOpen: () => {
          Swal.showLoading();
          postApiGeneric(
            "mercadoPago/facturar",
            { objPedido: objEnvioPedido, listaCarritoCompras: JSON.stringify(products.value) },
            (retorno) => {
              if (retorno.Cod === 200) {
                window.location.replace(retorno.Url);
                return retorno;
              }
            }
          );
        },
      }).then((result) => {
        if (result.dismiss) {
          Swal.fire({
            title: `Confirme su compra`,
          });
        }
      });
    };


    const loadPayExpress = () => {
      cliente.cuotas = cuotaSelected.value;
      if (productsDescuento.value.length) {
        products.value = productsDescuento.value;
      }
      childComponentRef.value.cargarPayExpress();
    };

    const childSiemprePagoRef = ref();

    const loadSiemprePago = (siemprePago) => {
      if (!validarCliente()) {
        return;
      }
      siemprePagoSelected.value = siemprePago;
      childSiemprePagoRef.value.cargarModal((modal) => {
        modal.show();
      });
    };

    const continuarComprando = () => {
      if (pasos.value == 2) {
        cambiarPaso(1);
        return;
      }
      router.push({ name: 'Home' });
    };

    const setearDiv = () => {
      /// genera el div que vincula los microdatos con el pixel
      let div = document.createElement('div');
      div.id = 'divPixel';
      div.setAttribute('itemprop', 'interactionCount');
      div.setAttribute('content', '1');
      div.setAttribute('data-event', 'initiate_checkout');
      document.head.appendChild(div);
    };


    const handleSelection = (selected) => {
      buyFeSelected.value = selected;
      if (selected) {
        payExpressSelected.value = {};
        cuotaSelected.value = 0;
        return;
      }
      condicionPagoSelected.value = 0;
    }

    const calcularDescuentoProductos = () => {
      // me copio el arreglo para no deshacer el origial
      productsDescuento.value = products.value;
      try {
        productsDescuento.value.forEach(
          (producto) => {
            let pctDescuento = parseFloat(producto.pctDescuento) + parseFloat(payExpressSelected.value.descBanco) + parseFloat(payExpressSelected.value.descComercio);
            producto.precioVenta =
              parseFloat(
                calcSalePrice(
                  producto.precioUnitario,
                  pctDescuento,
                  decimal
                )
              ) +
              adjust10th(
                issuserParam.value.configRedondeoDecimas,
                calcSalePrice(
                  producto.precioUnitario,
                  pctDescuento,
                  decimal
                ),
                decimal
              );
            producto.subTotal = calcSubTotal(
              producto.precioVenta,
              producto.cantidad,
              decimal
            );
            binDescuento.value.precioDescuento += producto.subTotal;
          }
        )
        totalDesc.value = binDescuento.value.precioDescuento;
        payExpressSelected.value.bin = binDescuento.value.bin;
      } catch (e) {
        throw new Error("Error -->", e)
      }
    }

    const handleBinValido = () => {
      binDescuento.value.mensaje = "Su cuenta se ha verificado correctamente";
      binDescuento.value.claseMensaje = "text-success";

      binDescuento.value.precioDescuento = 0;
      calcularDescuentoProductos();

      binDescuento.value.clasePrecioAnterior = "text-decoration-line-through text-muted";
      binDescuento.value.clasePrecioDescuento = "text-danger"
    }

    const validarBin = () => {
      let binValido;
      binDescuento.value.clasePrecioAnterior = "";
      binDescuento.value.precioDescuento = undefined;

      if (binDescuento.value.bin === "" || binDescuento.value.bin.length < 6) {
        binDescuento.value.mensaje = "Ingrese los primeros 6 dígitos de su tarjeta para validar su cuenta santander";
        binDescuento.value.claseMensaje = "text-muted";
        return;
      }

      if (!isNaN(binDescuento.value.bin)) {
        binValido = bines.value.find((item) => item.bin === parseInt(binDescuento.value.bin) && item.tipo === payExpressSelected.value.tipo)
      }

      if (isNaN(binDescuento.value.bin) || !binValido) {
        binDescuento.value.mensaje = "El numero ingresado no corresponde a una tarjeta Santander " + payExpressSelected.value.sello;
        binDescuento.value.claseMensaje = "text-danger";
        return;
      }

      if (binValido) {
        handleBinValido();
      }
    }



    onMounted(() => {
      if (issuserParam.value.generaCatalogo != undefined && issuserParam.value.generaCatalogo === "S") {
        setearDiv();
      }
      window.scrollTo(0, 0);
    });

    onUnmounted(
      () => {
        // CHECKEO QUE NO HAYA VINCULACIONES DEL EVENTO CON EL PIXEL AL MOMENTO DE SALIR DE LA PAGINA
        if (document.getElementById("divPixel") != undefined) {
          document.head.removeChild(document.getElementById("divPixel"));
        }
      }
    );

    watch(
      () => payExpressSelected.value,
      (newParams, oldValue) => {
        if (!newParams) return;
        if (Object.keys(newParams).length > 0 && Object.keys(oldValue).length <= 0 && buyFeSelected.value) {
          console.log("entra aca")
          buyFeRef.value.handleSelection();
        }
        binDescuento.value = {}
        productsDescuento.value = [];
        totalDesc.value = 0;
      }
    )

    provide("pedidosCheck", pedidosCheck);
    provide("totalDesc", totalDesc);
    provide("computedTotalDesc", computedTotalDesc)
    provide("computedTotalCheckout", computedTotal);
    provide("totalCheckout", total);
    provide("productsCheckout", products);
    provide("precioEnvioCheckout", precioEnvio);
    provide("cliente", cliente);
    provide("validarCliente", validarCliente);
    provide("payExpressSelected", payExpressSelected);
    provide("siemprePagoSelected", siemprePagoSelected);

    return {
      buyFeSelected,
      buyFeRef,
      totalDesc,
      computedTotalDesc,
      capitalizarPalabras,
      titularCuenta,
      condicionPagoSelected,
      condicionesPago,
      handleSelection,
      cuotaSelected,
      payExpressSelected,
      cuotas,
      instanceTrack,
      terminosYCondiciones,
      styleClass,
      validarTerminosCondiciones,
      cart,
      cliente,
      calcTotal,
      products,
      total,
      issuserParam,
      adjust10,
      precios,
      precioEnvio,
      computedTotal,
      sellosPayExpress,
      sellosSiemprePago,
      decimal,
      loadPayExpress,
      validarmediopago,
      childComponentRef,
      loadSiemprePago,
      childSiemprePagoRef,
      continuarComprando,
      loadingData,
      formatPrice,
      descuentos,
      binDescuento,
      validarBin,
      pasos
    };
  },
};
</script>
<style>
@media(min-width: 576px) {
  .sellos {
    min-width: 50px !important;
  }
}

.titulo-terminos {
  text-align: left !important;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  border-bottom: 2px solid rgb(197, 190, 190) !important;
}

.swal2-popup {
  border-radius: 0 !important;
  max-height: 90vh !important;

  overflow-y: auto !important;
}

.popup-terminos {
  max-height: 100vh !important;
  width: 80% !important;
}

/* .contenido-terminos{
  max-height: 70vh!important;
  overflow-y: auto!important;
} */
.boton-oculto {
  display: none !important;
}

.boton-cerrar {
  padding-top: 3% !important;
  height: 40px !important;
  box-shadow: none !important;
}

.boton-cerrar:focus {
  box-shadow: none !important;
  color: v-bind(styleClass) !important;
}

.bg-dircret {
  background-color: rgb(221, 218, 218) !important;
}

.border-bottom-envio {
  border-bottom: none !important;
}

@media(max-width: 995px) {
  .border-bottom-envio {
    border-bottom: 1px solid rgb(223, 221, 221) !important;
  }
}
</style>