<template>
    <div class="row mt-2" v-if="prodDestacados.length > 0">
        <h3 class="pb-2"
            :class="issuserParam.fuenteTitulo && issuserParam.fuenteTitulo != '-1' ? 'title-client' : 'text-center'">
            Productos destacados</h3>
        <Carousel :settings="settings" :items-to-show="1" :breakpoints="breakpoints" :wrap-around="true"
            :mouseDrag="breakpoint != 'xl' || params.cantItemsDestacadosLg != prodDestacados.length ? true : false" 
            :touchDrag="breakpoint != 'xl' || params.cantItemsDestacadosLg != prodDestacados.length ? true : false" class="border-top" id="categoriaDestacada" 
            :class="breakpoint == 'xl' && params.cantItemsDestacadosLg == prodDestacados.length ? 'categories-adjust' : ''"
            :transition="300">

            <Slide v-for="producto in prodDestacados" :key="producto.codigo" v-bind:id="'producto_' + producto.codigo"
                class="pt-3" :class="breakpoint == 'xl' && params.cantItemsDestacadosLg == prodDestacados.length ? 'w-auto px-3' : 
                breakpoint == 'sm' ? 'px-0':'w-auto px-5'">
                <div class="card bg-transparent text-white border-0 mt-1" v-if="producto.imagen">
                    <a href="javascript:void(0);" class="position-relative" @click="viewDetail(producto)">
                        <img :src="producto.ruta + producto.imagen" class="w-auto img-destacada"
                            :alt="producto.nombreProducto" />
                    </a>
                </div>
            </Slide>

            <template v-if="breakpoint != 'xl' || params.cantItemsDestacadosLg != prodDestacados.length" #addons>
                <Navigation />
            </template>
        </Carousel>
    </div>
</template>
<script>
import { onBeforeMount, ref, inject, computed } from 'vue';
import { Carousel, Navigation, Slide } from "vue3-carousel";
import router from "@/router";
export default {
    components: {
        Carousel,
        Slide,
        Navigation,
    }, props: {
        params: {
            type: Object,
            default: () => ({
                cantItemsDestacadosMd: 2,
                cantItemsDestacadosLg: 3
            })
        },
        colorFlechas: String

    }, data() {
        return {
            fixed: "",
            drop: "",
            sizeIcon: "",
            settings: {
                itemsToShow: 1,
                snapAlign: "center",
            },
        };
    },
    setup(props) {
        const $axios = inject("$axios");

        const prodDestacados = ref({})
        const alturaImgLg = ref(props.params.alturaImgDestacadosLg + "px");
        const alturaImgMd = ref(props.params.alturaImgDestacadosMd + "px");
        const alturaImgSm = ref(props.params.alturaImgDestacadosSm + "px");
        const colorFlechasCarousel = ref(props.colorFlechas);
        const issuserParam = inject("issuserParam");
        const width = ref(window.innerWidth);

        const breakpoints = computed(() => {
            return {
                500: {
                    itemsToShow: 1,
                    snapAlign: "center",
                },
                ///itemsToShow simepre debe ser numerico sino se rompe el carousel
                765: {
                    itemsToShow: parseFloat(props.params.cantItemsDestacadosMd),
                    snapAlign: "start",
                },965: {
                    itemsToShow: parseFloat(props.params.cantItemsDestacadosLg),
                    snapAlign: "center",
                },
                1200: {
                    itemsToShow: parseFloat(props.params.cantItemsDestacadosLg),
                    snapAlign: "center",
                },
            };
        });

        onBeforeMount(
            () => {
                $axios
                    .get(`/productos/destacados`)
                    .then((result) => {
                        if (result.data.cod_respuesta == "00") {
                            prodDestacados.value = result.data.retorno
                        }
                    });
            }

        );

        const viewDetail = (producto) => {
            if (producto.idProductoBase) {
                router.push({ name: "DetailArticle", params: { id: producto.idProductoBase } });
                return;
            }
            router.push({ name: "DetailArticle", params: { id: producto.codigo } });
        };

        

        window.addEventListener('resize', () => {
            width.value = window.innerWidth;
        });

        ///esto es para ajustar los slides
        const breakpoint = computed(() => {
            if (width.value < 500) return 'sm';
            if (width.value < 765) return 'md';
            if (width.value < 1200) return 'lg';
            return 'xl';
        });
        
        return {
            breakpoint,
            prodDestacados,
            breakpoints,
            viewDetail,
            alturaImgLg,
            alturaImgMd,
            alturaImgSm,
            colorFlechasCarousel,
            issuserParam
        }
    }
}
</script>
<style>
#categoriaDestacada>.carousel__prev,
#categoriaDestacada>.carousel__next {
    background-color: transparent !important;

}

#categoriaDestacada>.carousel__prev .carousel__icon,
#categoriaDestacada>.carousel__next .carousel__icon {
    color: v-bind(colorFlechasCarousel) !important;
}

#categoriaDestacada .img-destacada {
    height: v-bind(alturaImgLg) !important;
}

@media(max-width: 1250px) {
    #categoriaDestacada .img-destacada {
        height: v-bind(alturaImgMd) !important;
    }
}

@media(max-width: 700px) {
    #categoriaDestacada .img-destacada {
        height: v-bind(alturaImgSm) !important;
    }
}


.categories-adjust {
    display: flex !important;
    justify-content: center !important;
}

</style>