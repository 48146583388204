<template>
  <div class="row border-top border-2 mt-3" v-if="!productos.colReturn || !productos.colReturn.length"
    id="articlesDisplay">
    <div class="col text-center">
      <h5 :class="issuserParam.fuenteSubtitulo && issuserParam.fuenteSubtitulo != '-1' ? 'subtitle-client' : ''">No hay
        Artículos</h5>
    </div>
  </div>
  <div class="row" v-else>
    <div class="col">
      <div class="row mb-5">
        <div class="px-0 px-sm-1 card-desk mt-3" :class="[
          getResponsiveCard(issuserParam.cantidadProductosMostrar)
            ? getResponsiveCard(issuserParam.cantidadProductosMostrar)
            : getResponsiveCard('1'),
        ]" v-for="producto in productos.colReturn" :key="producto.codigo">
          <article-product class="margin-1" :product="producto" />
        </div>
      </div>
      <div class="row mt-2">
        <nav aria-label="Page navigation example">
          <ul class="pagination justify-content-center flex-wrap">
            <li class="page-item" :class="[pagActive > 1 ? '' : 'text-danger']">
              <a  class="page-link" aria-disabled="true" v-on:click="cambiarPagina(pagActive - 1)">
                <fa icon="arrow-left" />
              </a>
            </li>
            <li class="page-item" v-for="index in nroPaginas" :key="index" :class="[pagActive === index ? 'active' : '']">
              <a  class="page-link" v-on:click="cambiarPagina(index)">{{ index }}</a>
            </li>
            <li class="page-item" :class="[pagActive < nroPaginas ? '' : 'disabled']">
              <a class="page-link">
                <fa icon="arrow-right" v-on:click="cambiarPagina(pagActive + 1)" />
              </a>
            </li>
          </ul>
        </nav>
      </div>

    </div>
  </div>
</template>

<script>
import { inject, ref, watchEffect, watch, onMounted } from "vue";
import { getResponsiveCard, capitalizarPalabras } from "@/composable/functions";
import ArticleProduct from "@/components/ArticleProduct.vue";
export default {
  components: {
    ArticleProduct,
  }, props: {
    pageTrack: String,
  },
  setup(props) {
    const decimal = inject("decimal");
    const productos = inject("productos");
    const cargarProductos = inject("cargarProductos");
    const count = ref(24);
    const pagActive = inject("pagActive");
    const paramsProductos = inject("paramsProductos");
    const issuserParam = inject("issuserParam");
    const nroPaginas = ref(1);
    const favList = ref([]);
    const pageTrack = ref(props.pageTrack);
    const ids_track = ref([]);

    favList.value = localStorage.getItem("fav")
      ? JSON.parse(localStorage.getItem("fav"))
      : [];


    const activarPageView = () => {
      try {
        let div = document.createElement('div');
        div.id = 'divPixel';
        div.setAttribute('itemprop', 'interactionCount');
        div.setAttribute('content', '1');

        let event = pageTrack.value === "Home" ? 'page_view' : 'view_category';
        div.setAttribute('data-event', event);
        document.head.appendChild(div);

      } catch (err) {
        console.log(err)
      }
    }

    const generarJsonLd = () => {
      let arrayProductosMicroData = [];
      ids_track.value = [];
      productos.value.colReturn.forEach(
        (pr, index) => {
          let producto = {
            "@type": "Product",
            'position': index + 1,
            "itemCondition": "https://schema.org/NewCondition",
            "productID": pr.codigo,
            "category": pr.google_category ? pr.google_category : "1604",
            "sku": pr.codigo,
            'name': capitalizarPalabras(pr.nombreProducto),
            "description": pr.descripcion,
            "url": "https://www.pacampania.com.uy/detail/" + pr.codigo,
            "image": pr.ruta + pr.imagen,
            "offers": {
              "@type": "Offer",
              "price": parseFloat(pr.precioUnitario).toFixed(decimal),
              "priceCurrency": "UYU",
              "availability": pr.stock > 0 ? 'https://schema.org/InStock' : "https://schema.org/OutOfStock"
            },
          };
          ids_track.value.push(pr.codigo);
          arrayProductosMicroData.push(producto);
        }
      )
      return arrayProductosMicroData;
    }

    // Crea una función para agregar una etiqueta meta
    const addMetaTag = (property, content) => {
      const metaTag = document.createElement('meta');
      metaTag.setAttribute('property', property);
      metaTag.setAttribute('content', content);
      document.head.appendChild(metaTag);
    }

    const limpiarMetaTags = () => {
      const ogMetaTags = document.head.querySelectorAll('meta[property^="og:"]');

      ogMetaTags.forEach(tag => {
        tag.remove();
      });
    }

    const limpiarMicrodatosAnteriores = () => {
      //CHECKEO QUE NO HAYA MICRDATOS INSERTADOS
      const scripts = Array.from(document.head.getElementsByTagName('script'));
      scripts.forEach(script => {
        if (script.type === "application/ld+json") {
          document.head.removeChild(script);
        }
      });

      // CHECKEO QUE NO HAYA VINCULACIONES ANTERIORES CON EL PIXEL
      if (document.getElementById("divPixel") != undefined) {
        document.head.removeChild(document.getElementById("divPixel"));
      }

    }


    const setearMicrodatosCatalogo = () => {
      try {
        //limpio microdatos anteriores
        limpiarMetaTags();
        limpiarMicrodatosAnteriores();
        //genero los nuevos
        let jsonLd = generarJsonLd();

        let script = document.createElement('script');
        script.type = "application/ld+json";
        script.textContent = JSON.stringify(jsonLd);
        //seteo los microdatos
        document.head.appendChild(script);

        addMetaTag('og:site_name', 'Pacampaña');
        addMetaTag('og:type', 'article');
        //  addMetaTag('og:url', pageTrack.value === "Home" ? 'https://www.pacampania.com.uy': window.location.href );
        addMetaTag('og:title', productos.value.colReturn[0].familia ? productos.value.colReturn[0].familia : "Ropa de campo");
        addMetaTag('og:availability', "in stock");
        addMetaTag('og:description', productos.value.colReturn[0].familia ? productos.value.colReturn[0].familia : "Ropa de campo");
      }catch(err){
        console.error(err);
      }

    }


    const track = () => {
      try {
        if (issuserParam.value.generaCatalogo != undefined && issuserParam.value.generaCatalogo === "S" && productos.value.length > 0) {
          setearMicrodatosCatalogo();
          activarPageView();
          let event = 'ViewContent';
          let category = pageTrack.value === "Home" ? '' : "{category:'" + productos.value.colReturn[0].familia + "'},";
          let pixelScript = `fbq('track', '` + event + `', {content_ids: ` + JSON.stringify(ids_track.value).replace(/"/g, "'") + `}, ` + category + ` {content_type: 'product'})`;
          eval(pixelScript);
        }
      } catch (err) {
        console.error(err)
      }
    }


    watch(
      () => productos.value,
      (newParams) => {
        if (!newParams) return;
        if (pageTrack.value != "Home") {
          track();
        }
      }
    );


    onMounted(() => {
      if (props.pageTrack === "Catalog") {
        track();
      }
    });

    const cambiarPagina = (index) => {
      try {
        pagActive.value = index;
        let start = index === 1 ? index : (index - 1) * paramsProductos.value.count;
        paramsProductos.value.start = start;
        cargarProductos(paramsProductos.value);

      } catch (err) {
        console.log(err)
      }

    };

    watchEffect(() => {
      nroPaginas.value = productos.value.cantTotResult
        ? Math.ceil(productos.value.cantTotResult / paramsProductos.value.count)
        : 1;
    });

    return {
      productos,
      pagActive,
      count,
      nroPaginas,
      cambiarPagina,
      issuserParam,
      decimal,
      getResponsiveCard,
    };
  },
};

</script>

<style>
.pagination>li>a {
  background-color: white;
  color: #6c757d;
}

.pagination>li>a:focus,
.pagination>li>a:hover,
.pagination>li>span:focus,
.pagination>li>span:hover {
  color: #5a5a5a;
  background-color: #eee;
  border-color: #ddd;
}

.pagination>.active>a {
  color: white;
  background-color: #6c757d !important;
  border: solid 1px #6c757d !important;
}

.pagination>.active>a:hover {
  background-color: #6c757d !important;
  border: solid 1px #6c757d;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}


@media(max-width: 576px) {
  .margin-1 {
    margin: 2%;
  }
}
</style>