<template>
  <div class="form-check d-flex">
    <div class="row">
     <div class="col-1">
        <input class="form-check-input radio-checkout" type="radio" name="radioMediopago" 
        id="radioMedioPago_FE" :value="selected" @change="handleSelection"/>
     </div> 
     <div class="col-6 px-0">
      <img class="img-fluid" src="img/logo/fe.png" alt="Factura Express" />
     </div> 
    </div>
  </div>
</template>

<script>
import {ref} from "vue";
export default {
  name: "BuyFE",
  emits: ['selection'],
  setup( _,{ expose,emit } ) {
    const selected = ref(false);

    function handleSelection() {
      selected.value = selected.value === false ? true : false;
      console.log("value " + selected.value)
      emit('selection', selected.value);
    }
    
    expose({ handleSelection });
    return {
      selected,
      handleSelection
    };
  },
};
</script>