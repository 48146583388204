<template>
  <header>
    <nav class="navbar d-block client-navbar navbar-expand-lg bg-transparent mb-0 pt-0 pb-0 flex-column " :class="[fixed]"
      id="navbar">
      <div id="div-nav"
        class="container-fluid mb-0 bg-nav-client container-nav justify-content-between">
        <div class="col-1 d-block order-1 order-md-1 order-lg-2">
          <a href="#offcanvasExample" class="btn text-client p-0 p-md-2 p-lg-2" id="offcanvasId" data-bs-toggle="offcanvas" role="button"
            aria-controls="offcanvasExample">
            <fa icon="bars" class="d-block mx-auto" :class="[sizeIcon]" />
          </a>
        </div>
        <div class="col-5 col-sm-4 col-md-4 col-lg-auto order-2 order-md-2 order-lg-1">
          <router-link to="/" class="navbar-brand px-0 mx-0 start-0 w-100" aria-current="page" id="logo">
            <img :src="issuserParam.rutaLogo + issuserParam.logo" class="client-size-logo img-fluid px-1" />
          </router-link>
        </div>
        <!----BOTON QUE DESPLIEGA EL SLIDE DE LAS CATEGORIAS -->
        <!---<a href="#offcanvasExample" class="btn text-client d-none d-md-none d-lg-block elemento-izquierdo" id="offcanvasId"
          data-bs-toggle="offcanvas" role="button" aria-controls="offcanvasExample">
          <fa icon="bars" class="d-block mx-auto" :class="[sizeIcon]" />
        </a> -->

        <div class="col-5 col-sm-4 col-md-2 col-lg-2 d-flex justify-content-end order-3 order-md-3 order-lg-3">
          <div class="row">
            <!----BOTON DE BUSCAR PARA REPSONSIVE-->
            <div class="nav-item d-md-block d-lg-none d-block col-auto px-0">
              <a class="nav-link btn text-client" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasTop"
                aria-controls="offcanvasTop">
                <fa icon="search" class="d-block mx-auto" :class="[sizeIcon]" />
              </a>
            </div>
            <!----BOTON DEL CONTACTO PARA REPSONSIVE-->
            <div v-if="issuserParam.ocultarVentanaContacto != 2"
              class="nav-item d-md-block d-lg-none d-block col-auto px-0">
              <router-link to="/about" class="nav-link text-client" v-slot="{ isActive, isExactActive }">
                <a :class="isActive && isExactActive ? 'text-secondary' : 'text-client'
                  ">
                  <fa icon="phone" class="bi d-block mx-auto" :class="[sizeIcon]" />
                </a>
              </router-link>
            </div>
            <!----BOTON DEL CARRITO PARA REPSONSIVE-->
            <div class="nav-item d-md-block d-lg-none d-block col-auto px-0">
              <router-link to="/cart" class="
                    position-relative
                    nav-link
                    text-client
                    border-start-0
                    rounded-0
                  " v-slot="{ isActive, isExactActive }">
                <p class="mb-0" v-if="issuserParam.deshabilitarCarrito != 'S'"
                  :class="isActive && isExactActive ? 'text-secondary ' : 'text-client'">
                  <span class="position-absolute top-0 start-80 translate-middle badge rounded-pill bg-client"
                    v-if="cart.length">
                    {{ cart.length }}
                  </span>
                  <fa icon="shopping-cart" class="bi d-block mx-auto" :class="[sizeIcon]" />
                </p>
              </router-link>
            </div>
          </div>
        </div>

        <!--ESTE DIV TIENE LOS BOTONES Y EL BUSCADOR PARA PC-->
        <div class="collapse navbar-collapse pb-1 col-5 col-md-2 col-lg-2 order-3" id="navbarSupportedContent">
          <ul class="nav col-12 justify-content-center justify-content-lg-end mt-md-0 text-small
              " style="margin-bottom: -1rem">
            <div class="mt-2 d-none d-md-block d-lg-block" id="search-prods">
              <div class="input-group">
                <input type="text" autocomplete="off" class="form-control" id="search-pc"
                  :class="issuserParam.estiloBuscador && issuserParam.estiloBuscador == 'T' ? 'search-pc-discreto' : ''"
                  :placeholder="issuserParam.estiloBuscador && issuserParam.estiloBuscador == 'T' ? '' : 'Buscar...'"
                  aria-label="Buscar" v-model="paramsProductos.search" @input="changueSearch"
                  aria-describedby="button-addon2" @keyup.enter="changeCategoria" />
                <button class="btn btn-outline-secondary btn-buscar"
                  :class="issuserParam.estiloBuscador && issuserParam.estiloBuscador == 'T' ? 'btn-buscar-discreto' : ''"
                  type="button" v-on:click="changeCategoria" title="Buscaar">
                  <fa icon="search" />
                </button>
              </div>
              <div id="lista_prods" style="float: !important; position: fixed!important;z-index: 100!important;">
                <ul class="list-group rounded-4" id="ul-prods"
                  style="max-height:500px!important;overflow-y: scroll!important;">
                  <li class="list-group-item border-0 li st-buscador" v-for="producto in productosSearch"
                    :key="producto.codigo"><a @click="viewDetail(producto)">{{ producto.nombreProducto }}</a></li>
                </ul>
              </div>
            </div>
            <li class="nav-item" v-if="issuserParam.ocultarVentanaContacto != 2">
              <router-link to="/about" class="nav-link pt-3" v-slot="{ isActive, isExactActive }">
                <p :class="isActive && isExactActive ? 'text-secondary' : 'text-client'
                  " class="pb-lg-0 contacto">
                  <fa icon="phone" class="bi d-block mx-auto" :class="[sizeIcon]" />
                </p>
              </router-link>
            </li>
            <li class="nav-item d-md-block d-lg-block d-none">
              <router-link to="/cart" class="
                    nav-link
                    text-client
                    position-relative
                    border-start-0
                    rounded-0
                    py-3
                  " v-slot="{ isActive, isExactActive }">
                <p v-if="issuserParam.deshabilitarCarrito != 'S'" :class="isActive && isExactActive ? 'text-secondary ' : 'text-client'
                  ">
                  <fa icon="shopping-cart" class="bi d-block mx-auto my-1" :class="[sizeIcon]" />

                  <span class="
                        position-absolute
                        translate-middle
                        badge
                        rounded-pill
                        bg-client
                      " style="top: 0.7rem !important" v-if="cart.length">
                    {{ cart.length }}
                    <span class="visually-hidden">En el carrito</span>
                  </span>
                </p>
              </router-link>
            </li>
            <li class="nav-item d-none">
              <a class="nav-link text-client" href="javascript:void(0);">
                <fa icon="user-circle" class="bi d-block mx-auto mb-1" :class="[sizeIcon]" />
                Usuario
              </a>
            </li>
          </ul>
        </div>
      </div>
      <!--- ESTE DIV TIENE EL SLIDE DE LAS CATEGORIAS-->
      <div v-if="mostrarCategoriasNavbar != 'N'" :class="categoriasColorClient != '#0000005b' ? 'box-shadow-b' : ''"
        id="categorias" class="
            d-none d-sm-none d-md-none d-lg-block  
            px-3
            categorias
          ">
        <Carousel class="catalogNavbar" v-bind="settings" :breakpoints="breakpoints" :wrap-around="true"
          :items-to-show="2.5" :itemsToScroll="2" :transition="700" >
          <Slide class="nav-item categories mx-3" v-for="categoria in categorias" :key="categoria.codigoGrupo"
            style="height:min-content!important">
            <div class="dropdown" v-if="categoria.items.length > 0">
              <a class="nav-link dropdown-toggle text-white a-categories"
                :class="issuserParam.fuenteSubtitulo && issuserParam.fuenteSubtitulo != '-1' ? 'subtitle-client' : ''"
                role="button" id="dropdownMenuLink" aria-expanded="false" @click="searchCategorias(categoria)"
                @mouseenter="loadModalSubCategorias(categoria)">
                {{ categoria.nombreGrupo }}
              </a>
              <ul class="dropdown-menu mt-0 py-0" aria-labelledby="dropdownMenuLink"
                style="z-index: 9999!important;position:static!important; overflow-y: auto!important;max-height: 80vh!important;">
                <li class="border border-bottom-1" v-for="item in categoriaSelected.items" :key="item.codigo"><a
                    class="dropdown-item li-subCategories py-3" style="font-size: 0.80rem!important;" href="#"
                    @click="searchGrupo(item.codigo, item)">{{ item.nombre }}</a></li>
              </ul>
            </div>
            <a class="nav-link text-white a-categories"
              :class="issuserParam.fuenteSubtitulo && issuserParam.fuenteSubtitulo != '-1' ? 'subtitle-client' : ''"
              role="button" @click="searchCategorias(categoria)" v-else>
              {{ categoria.nombreGrupo }}
            </a>
          </Slide>
          <template #addons>
            <Navigation />
          </template>
        </Carousel>
      </div>
    </nav>
    <!----Ete div tiene el offcanvas que se despliega con un buscador para responsive --->
    <div class="offcanvas offcanvas-top rounded-0  search-responsive" tabindex="-1" id="offcanvasTop"
      aria-labelledby="offcanvasTopLabel" style="height:60px!important">
      <div class="card rounded-0 p-0 justify-content-center">
        <div class="row justify-content-center align-items-center content-align-center">
          <div class="col-10">
            <input type="text" id="search_responsive" class="h-100 border-0 w-100" placeholder="Buscar..."
              aria-label="Buscar" aria-describedby="button-addon2" v-model="paramsProductos.search" @keyup="changueSearch"
              @keyup.enter="changeCategoria" autocomplete="off" />
          </div>
          <div class="col-2 pt-1">
            <button type="button" id="clearSearch" class="btn-close" data-bs-dismiss="offcanvas" @click="clearSearch"
              aria-label="Close"></button>
          </div>
        </div>
        <div class="row" id="lista_prods">
          <div class="col-12">
            <ul class="list-group rounded-0" style="max-height:500px!important;overflow-y: scroll!important;">
              <li class="list-group-item border-0" v-for="producto in productosSearch" :key="producto.codigo"><a
                  @click="viewDetail(producto)">{{ producto.nombreProducto }}</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { inject, computed, ref/* , onMounted, nextTick */ } from "vue";

import { Carousel, Navigation, Slide } from "vue3-carousel";

import router from "@/router";
export default {
  name: "navbar",
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data() {
    return {
      fixed: "",
      drop: "",
      sizeIcon: "",
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
      },
    };
  },
  setup() {
    const categorias = inject("categorias");
    const tieneGruposCategorias = inject("tieneGruposCategorias");
    const cargarProductos = inject("cargarProductos");
    const pagActive = inject("pagActive");
    const paramsProductos = inject("paramsProductos");
    const issuserParam = inject("issuserParam");
    const cart = inject("cart");
    const styleClass = inject("styleClass");
    //esta variable es la categoria seleccionada en la pantalla en que se encuentre
    const categoriaSelected = ref({});
    const productos = inject("productosSearch");
    const productosSearch = ref([]);
    const subSelected = inject("subSelected");
    const sizeSlide = ref("32px")
    //esta variable es la categoria seleccionada globalmente
    const categoriaSelectedSlide = inject("categoriaSelected")
    const alturaNavBar = ref(0);
    const categoriasColorClient = inject("categoriasColorClient");
    const categoriasFontColorClient = inject("categoriasFontColorClient");
    const navClientColor = inject("navClientColor");
    const mostrarCategoriasNavbar = inject("mostrarCategoriasNavbar");
    const topFlechas = ref(0);

    //seteo las dos configs en objetos diferente porque en el css no se pueden pasar objetos
    const snapAlignCarousel = computed(() => {
      let config = {};
      if (categorias.value.length == issuserParam._value.cantitemscarouselGrande) {
        config.snapAlign = "start"
        return config;
      }
      config.snapAlign = "center"
      return config;
    });

    //seteo las dos configs en objetos diferente porque en el css no se pueden pasar objetos
    const carouselTrackConfig = computed(() => {
      let config = {};
      if (categorias.value.length == issuserParam._value.cantitemscarouselGrande) {
        config = "center";
        return config;
      }
      config = "unset";
      return config;
    });


    const breakpoints = computed(() => {
      return {
        500: {
          itemsToShow:
            categorias.value.length / 2 / 2 <= 3 ? 1 : 3,
          snapAlign: "center",
        },
        765: {
          itemsToShow:
            issuserParam._value.cantitemscarouselMedio != null ? parseFloat(issuserParam.value.cantitemscarouselMedio) : 4,
          snapAlign: "center",
        },
        1200: {
          itemsToShow:
            issuserParam._value.cantitemscarouselGrande != null ? parseFloat(issuserParam.value.cantitemscarouselGrande) : 4,
          snapAlign: snapAlignCarousel.value.snapAlign,
        },
      };
    });

    const searchGrupo = (codigo, item) => {
      pagActive.value = 1;
      paramsProductos.value.start = pagActive.value;
      paramsProductos.value.categoria = codigo;
      paramsProductos.value.orderby = 1;
      paramsProductos.value.search = "";
      subSelected.value = item;
      subSelected.value.codigoGrupo = categoriaSelected.value.codigoGrupo;
      paramsProductos.value.grupo = categoriaSelected.value.codigoGrupo;
      ///esto es para que no se me active el evento del pixel mas de una vez
      if (router.currentRoute.value.name != "Catalog") {
        sendFiltros();
        return;
      }
      cargarProductos(paramsProductos.value, sendFiltros);
    };

    const searchCategorias = (categoria) => {
      subSelected.value = null;
      paramsProductos.value.categoria = -1;
      pagActive.value = 1;
      paramsProductos.value.start = pagActive.value;
      paramsProductos.value.orderby = 1;
      paramsProductos.value.search = "";
      categoriaSelected.value = categoria;
      categoriaSelectedSlide.value = categoriaSelected.value;
      paramsProductos.value.grupo = categoria.codigoGrupo;
      cargarProductos(paramsProductos.value, sendFiltros);
    };

    const loadModalSubCategorias = (categoria) => {
      categoriaSelected.value = categoria;
    };

    const changeCategoria = () => {
      pagActive.value = 1;
      paramsProductos.value.categoria = -1;
      paramsProductos.value.grupo = -1;
      paramsProductos.value.start = pagActive.value;
      cargarProductos(paramsProductos.value, sendFiltros);
      productosSearch.value = [];
    }

    const viewDetail = (producto) => {
      document.getElementById("clearSearch").click();
      if (producto.idProductoBase != null) {
        router.push({ name: "DetailArticle", params: { id: producto.idProductoBase } });
        return;
      }
      router.push({ name: "DetailArticle", params: { id: producto.codigo } });
    };

    const clearSearch = () => {
      paramsProductos.value.search = "";
      productosSearch.value = [];
    }

    const sendFiltros = () => {
      if (paramsProductos.value.categoria != -1) {
        router.push({
          name: "Catalog",
          params: { categoria: categoriaSelected.value.nombreGrupo.toLowerCase(), subcategoria: subSelected.value.nombre.toLowerCase() },
        });
      } else if (paramsProductos.value.grupo != -1) {
        router.push({
          name: "Catalog",
          params: { categoria: categoriaSelected.value.nombreGrupo.toLowerCase() },
        });
      } else {
        router.push({ name: "Catalog" });
      }
    };

    const changueSearch = () => {
      productosSearch.value = [];
      if (paramsProductos.value.search != "") {
        productosSearch.value = productos.value.filter((producto) => {
          return producto.nombreProducto.toLowerCase().includes(paramsProductos.value.search.toLowerCase())
        });
      }
    }
    return {
      carouselTrackConfig,
      topFlechas,
      navClientColor,
      categoriasFontColorClient,
      categoriasColorClient,
      alturaNavBar,
      clearSearch,
      viewDetail,
      productos,
      productosSearch,
      changueSearch,
      sizeSlide,
      categorias,
      tieneGruposCategorias,
      paramsProductos,
      changeCategoria,
      searchGrupo,
      searchCategorias,
      issuserParam,
      cart,
      styleClass,
      loadModalSubCategorias,
      breakpoints,
      categoriaSelected,
      mostrarCategoriasNavbar
    };
  },
  created() {
    this.myEventHandler();
    window.addEventListener("resize", this.myEventHandler);
    // document.addEventListener("DOMContentLoaded", this.dropDownMultiLevel);
  },
  unmounted() {
    window.removeEventListener("resize", this.myEventHandler);
    // document.addEventListener("DOMContentLoaded", this.dropDownMultiLevel);
  },

  mounted() {
    // Definir una función que devuelve una Promise que se resolverá cuando se cumpla la condición
    const waitForCondition = () => {
      return new Promise(resolve => {
        // Comprobar la condición
        if (Object.keys(this.categorias).length != 0) {
          this.sizeSlide = document.getElementById("dropdownMenuLink").offsetHeight + "px";
          this.alturaNavBar = document.getElementById("navbar").offsetHeight + "px";
          resolve(); // Resuelve la Promise si la condición se cumple
        } else {
          // Espera 100ms y comprueba la condición nuevamente
          setTimeout(() => waitForCondition(), 100);
        }
      });
    };

    const ajustarAltoCategorias = () => {
      return new Promise(resolve => {
        // Comprobar la condición
        if (Object.keys(this.categorias).length != 0) {
          let alturaCategorias = document.getElementById("categorias").offsetHeight;
          this.topFlechas = alturaCategorias / 2 + "px"
          resolve(); // Resuelve la Promise si la condición se cumple
        } else {
          // Espera 100ms y comprueba la condición nuevamente
          setTimeout(() => ajustarAltoCategorias(), 100);
        }
      });

    }

    // Esperar a que se cumpla la condición antes de ejecutar el código
    async function init() {
      await waitForCondition();
      // Código a ejecutar después de que se cumpla la condición
    }
    init().then(
      ajustarAltoCategorias()
    )
  },
  methods: {
    myEventHandler() {

      this.fixed = "fixed-top";
      this.drop = "";
      this.sizeIcon = "fa-1x";

    },
    /*     dropDownMultiLevel() {
          document.querySelectorAll(".dropdown-menu").forEach(function (element) {
            element.addEventListener("click", function (e) {
              e.stopPropagation();
            });
          });
          // make it as accordion for smaller screens
          if (window.innerWidth < 992) {
            // close all inner dropdowns when parent is closed
            document
              .querySelectorAll(".navbar .dropdown")
              .forEach(function (everydropdown) {
                everydropdown.addEventListener("hidden.bs.dropdown", function () {
                  // after dropdown is hidden, then find all submenus
                  this.querySelectorAll(".submenu").forEach(function (
                    everysubmenu
                  ) {
                    // hide every submenu as well
                    everysubmenu.style.display = "none";
                  });
                });
              });
            document
              .querySelectorAll(".dropdown-menu a")
              .forEach(function (element) {
                element.addEventListener("click", function (e) {
                  let nextEl = this.nextElementSibling;
                  if (nextEl && nextEl.classList.contains("submenu")) {
                    // prevent opening link if link needs to open dropdown
                    e.preventDefault();
                    if (nextEl.style.display == "block") {
                      nextEl.style.display = "none";
                    } else {
                      nextEl.style.display = "block";
                    }
                  }
                });
              });
          }
        }, */
  },
};
</script>

<style lang="scss">
/*----------------------------------------*/
/* Header Menu Multi-Level Details
/*----------------------------------------*/
@media(min-width:992px) {
  .contacto {
    padding-top: 30% !important;
  }
}



/* Navbar container */
.navbar-details {
  overflow: hidden;
  /*background-color: #1a1a1a;*/
}

#search-pc:focus,
.navbar #offcanvasId:focus {
  outline: 0px !important;
  box-shadow: unset !important;
}

/* Links inside the navbar */
.navbar-details a {
  float: left;
  font-size: 16px;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  max-width: 120px !important;
}

.categorias {
  max-height: v-bind(sizeSlide) !important;
}

/* The dropdown container */
.dropdown-details {
  float: left;
  margin-right: 5px;
}

@media(max-width: 576px) {
  #contenedornumeroscabezal {
    display: none;
  }

  /*  #logo{
    max-width: 100px!important;
  } */
}

#search-prods,
#ul-prods {
  width: 430px !important;
}

#ul-prods {
  max-height: 500px !important;
  overflow-y: scroll !important;
}

@media(max-width:1376px) {

  #search-prods,
  #ul-prods {
    width: 330px !important;
  }
}

/* Dropdown button */
.dropdown-details .dropbtn-details {
  font-size: 16px;
  outline: none;
  padding: 14px 16px;
  background-color: #fff;
  color: #1a1a1a;
  border: solid #1a1a1a 0.5px;
  font: inherit;
  /* Important for vertical align on mobile phones */
  margin: 0;
  /* Important for vertical align on mobile phones */
}

.dropdown-details:hover .dropbtn-details {
  background: #444;
  color: #fff;
  border: solid #1a1a1a 0.5px;
}

.navbar-details a:hover {
  background-color: #fff;
  color: #1a1a1a;
  border: solid #1a1a1a 0.5px;
}

.navbar-details .menuCategoriaGrupo a {
  margin: 10px;
  text-align: center;
}

.navbar-details .menuCategoriaGrupo a {
  font-size: 14px;
  font-weight: 600;
  background: #444;
  color: #fff;
  padding: 15px 30px;
  display: inline-block;
  border: 1px solid #1a1a1a;
  text-transform: uppercase;
  transform: translateX(0px) !important;
}

.navbar-details .menuCategoriaGrupo a:hover {
  background: transparent;
  color: #1a1a1a;
}

/* Dropdown content (hidden by default) */
.dropdown-content-details {
  left: 30px;
  display: none;
  /*position: absolute;*/
  position: fixed;
  background-color: #f9f9f9;
  width: 90%;
  margin: 0 auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  min-height: 300px;
}

/* Mega Menu header-img, if needed */
.dropdown-content-details .header-img {
  background: red;
  padding: 16px;
  color: white;
}

.categories {
  width: 13em !important;
}



.dropdown-menu .li-subCategories:hover {
  background-color: #dfdada !important;
}

/* Show the dropdown menu on hover */
.dropdown-details:hover .dropdown-content-details {
  display: block;
}

.offcanvas-top #search_responsive {
  padding-left: 5% !important;
  height: 60px !important;
}

.offcanvas-top #search_responsive:focus {
  border: none !important;
  outline: none !important;
  height: 60px !important;
}


header .offcanvas-backdrop {
  backdrop-filter: none !important;
  background-color: transparent !important;
}

#offcanvas .lista_prods {
  overflow-y: scroll !important;
}


.elemento-izquierdo {
  position: relative !important;
  left: 0 !important;
}

.catalogNavbar .carousel__prev,
.catalogNavbar .carousel__next {

  background-color: transparent;
  color: v-bind(styleClass);
  top: v-bind(topFlechas);
}

.dark-mode .catalogNavbar .carousel__prev,
.dark-mode .catalogNavbar .carousel__next {
  box-sizing: content-box;
  color: v-bind(styleClass);
  // background-color: v-bind(styleClass);
}

#categorias {
  background-color: v-bind(categoriasColorClient) !important;

}

//si la barra de categorias no es transparente se le agrega esta clase
.box-shadow-b {
  box-shadow: 0 0.050rem 0.25rem v-bind(navClientColor) !important;
}

.search-responsive {
  top: v-bind(alturaNavBar) !important;
}

.catalogNavbar .a-categories {
  color: v-bind(categoriasFontColorClient) !important;
}

.navbar ::-webkit-scrollbar {
  width: 10px !important;
  /* Ancho de la barra de desplazamiento */
}

/* Estilo para el track (fondo) de la barra de desplazamiento */
.navbar ::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  /* Color de fondo del track */
}

/* Estilo para el thumb (agarre) de la barra de desplazamiento */
.navbar ::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Color del thumb */
}

.search-pc-discreto {
  background-color: v-bind(navClientColor) !important;
  border: 2px solid #ccc9c9 !important;
  border-radius: 0 !important;
}

.btn-buscar-discreto {
  border: solid 2px #ccc9c9 !important;
  border-radius: 0 !important;
  //box-shadow: #b89292!important;
  color: #9b9898 !important;
}

.catalogNavbar .carousel__track {
  justify-content: v-bind(carouselTrackConfig) !important;
}

.dropdown:hover .dropdown-menu {
  display: block !important;
}
</style>