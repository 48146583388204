import { createApp } from 'vue'
import App from './App.vue'
//import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faTwitter, faInstagram, faWhatsapp, faYoutube  } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(fas);
library.add(faFacebook);
library.add(faTwitter);
library.add(faInstagram);
library.add(faWhatsapp);
library.add(faYoutube);

import VueEasyLightbox from 'vue-easy-lightbox'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import "bootstrap"
import 'bootstrap/scss/bootstrap.scss'
import "./assets/dark-mode.css";

import "vue3-carousel/dist/carousel.css";


import titleMixin from '@/mixins/titleMixin'
import functionMixin from '@/mixins/functionsMixin'

///Esta es la configuracion de las cabeceras de autorizacion con la api key y el origin para que no de problemas de cors

const apiClient = axios.create({
    baseURL: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_URLAPI_PRODUCTION : process.env.VUE_APP_URLAPI,
  });
  
   apiClient.interceptors.request.use((config) => {
    config.headers['Authorization'] = 'Bearer ' + process.env.VUE_APP_APIKEY;
   // config.headers['Origin'] = 'http://localhost:8080';
    return config;
}); 



const app = createApp(App)
app.provide('$axios',apiClient);
app.provide('URLAPI',process.env.NODE_ENV === 'production' ? process.env.VUE_APP_URLAPI_PRODUCTION : process.env.VUE_APP_URLAPI);
app.provide('PUBLICPATH',process.env.NODE_ENV === 'production' ? process.env.VUE_APP_URL_PRODUCTION : '/');
app.provide('APIKEY',process.env.VUE_APP_APIKEY);
app.mixin(titleMixin);
app.mixin(functionMixin);
app.component('fa', FontAwesomeIcon).use(store).use(router).use(VueEasyLightbox).use(VueSweetalert2).mount('#app')

export default apiClient;