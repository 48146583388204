<template>
  <div>
    <Navbar />
    <main :style="{ marginTop: `${navbarHeight}px`, marginBottom: `${footerHeight}px` }" class="pt-0 d-block">
      <Slidebar />
      <div class="mb-5 mb-sm-0 mt-0 mt-sm-5">
        <router-view />
      </div>
      <loading-data v-if="isLoading" :appName="appName" tipo="T" />
    </main>
    <Foot />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Slidebar from "@/components/SlideBar.vue";
import Foot from "@/components/Foot.vue";
import { provide, inject, readonly, ref, reactive, onBeforeMount } from "vue";
import Swal from "sweetalert2";
import { extraerCodigoScript, extraerValorPropiedad, getDarkerColor, getFont } from "@/composable/functions";


export default {
  title: "Market Express",
  components: {
    Navbar,
    Slidebar,
    Foot,
  }, data() {
    return {
      navbarHeight: 0,
      footerHeight: 0
    }
  },
  created() {
    // const darkmode = window.matchMedia("(prefers-color-scheme: dark)").matches;
    const visualModes = { ligth: "ligth-mode", dark: "dark-mode" };
    let visualMode = "ligth";
    if (localStorage.getItem("visualMode")) {
      visualMode = localStorage.getItem("visualMode");
    }
    document.body.classList.add(visualModes[visualMode]);
  }, mounted() {
    setTimeout(
      () => {
        const navbar = document.getElementById('div-nav');
        const footer = document.getElementById('foot');
        this.navbarHeight = navbar.offsetHeight 
        this.footerHeight = footer.offsetHeight
      }, 300
    )
  },
  setup() {
    let decimal = 2;
    const categorias = ref([]);
    const tieneGruposCategorias = ref(0);
    const productos = ref({});
    const productosSearch = ref([]);
    const pagActive = ref(1);
    const $axios = inject("$axios");
    const urlApi = inject("URLAPI");
    const appVersion = process.env.VUE_APP_VERSION
    const appName = process.env.VUE_APP_NAME
    const params = ref({
      search: "",
      categoria: -1,
      grupo: -1,
      start: 1,
      count: 24,
      orderby: 1,
    });
    const issuserParam = ref({});
    const cart = ref([]);
    const productComplete = ref([]);
    const favList = ref([]);
    const styleClass = ref("var(--bs-success)");
    const navClientIcon = ref("var(--bs-success)");
    const navClientColor = ref("var(--bs-white)");
    const navCodigoColorIcono = ref("var(--bs-white)");
    const navsizeDefectoImagenTelefono = ref("200px");
    const navsizeNavBar = ref("1.5");
    const navsizeLogo = ref("50px");
    const navsizeDefectoImagen = ref("200px");
    const apiVersion = ref("");
    const subSelected = ref(null);
    const categoriaSelected = ref(null);
    const subcategoriesVisible = ref(false);
    const categoriasColorClient = ref("");
    const darkerColor = ref("");
    const categoriasFontColorClient = ref("");
    const mostrarCategoriasNavbar = ref("");

    const FPixel = ref("");
    const EventoMC = ref("");
    const VDominio = ref("");

    const terminosYCondiciones = ref([]);
    const isLoading = ref(true);
    let prProducto = {};
    let inicioHash = reactive({
      verProductos: { class: "active" },
      verFavoritos: { class: "" },
    });
    const sizeHeaderNavBar = ref("");
    const margenesLaterales = ref("");
    const estiloCategoriasImagenes = ref("");
    const configCatsImagenes = ref({});
    const estiloBuscador = ref("");

    const titleFont = ref("");
    const titleFontStyle = ref("");
    const titleAlignament = ref("");
    const titleFontColor = ref("");

    const subtitleFont = ref("");
    const subtitleFontStyle = ref("");

    const footerHeightClient = ref("");
    const bgFootClient = ref("");
    const colorFuenteCtegoriasImg = ref("");

    const descuentos = ref([]);
    const footTextClient = ref("");

    onBeforeMount(
      async () => {
        $axios
          .get(`/parametros`)
          .then((result) => {

            issuserParam.value = result.data.retorno;

            styleClass.value = issuserParam.value.codigoColorPrincipal ? issuserParam.value.codigoColorPrincipal : "var(--bs-success)";
            navClientColor.value = issuserParam.value.codigoColorBarra ? issuserParam.value.codigoColorBarra : "var(--bs-white)";
            navCodigoColorIcono.value = issuserParam.value.codigoColorIcono ? issuserParam.value.codigoColorIcono : "var(--bs-white)";
            navClientIcon.value = issuserParam.value.codigoColorIcono ? issuserParam.value.codigoColorIcono : "var(--bs-success)";
            navsizeDefectoImagenTelefono.value = issuserParam.value.sizeDefectoImagenTelefono ? issuserParam.value.sizeDefectoImagenTelefono + "px" : "200px";
            navsizeDefectoImagen.value = issuserParam.value.sizeDefectoImagen ? issuserParam.value.sizeDefectoImagen + "px" : "200px";
            navsizeLogo.value = issuserParam.value.sizeLogo ? issuserParam.value.sizeLogo + "px" : "50px";
            navsizeNavBar.value = issuserParam.value.SizeNavBar ? issuserParam.value.SizeNavBar : "1.5";
            apiVersion.value = issuserParam.value.apiVersion ? issuserParam.value.apiVersion : "1";
            document.title = process.env.VUE_APP_NAME ? process.env.VUE_APP_NAME : document.title;
            FPixel.value = result.data.retorno.FPixel ? result.data.retorno.FPixel : "";
            EventoMC.value = result.data.retorno.EventoMC ? result.data.retorno.EventoMC : "";
            VDominio.value = result.data.retorno.VDominio ? result.data.retorno.VDominio : "";
            terminosYCondiciones.value = result.data.retorno.terminosYCondiciones && result.data.retorno.terminosYCondiciones != "N" ? result.data.retorno.terminosYCondiciones : [];
            categoriasColorClient.value = result.data.retorno.codigoColorCategorias ? result.data.retorno.codigoColorCategorias : "#0000005b";
            darkerColor.value = result.data.retorno.codigoColorCategorias ? getDarkerColor(result.data.retorno.codigoColorCategorias, 20) : "#0000005b";
            categoriasFontColorClient.value = result.data.retorno.codigoColorFuenteCategorias ? result.data.retorno.codigoColorFuenteCategorias : "#e8dede";
            mostrarCategoriasNavbar.value = result.data.retorno.mostrarCategoriasNavbar ? result.data.retorno.mostrarCategoriasNavbar : "S";
            sizeHeaderNavBar.value = result.data.retorno.sizeHeaderNavBar ? result.data.retorno.sizeHeaderNavBar + "px" : "100px";
            margenesLaterales.value = result.data.retorno.margenesLaterales ? result.data.retorno.margenesLaterales + "px" : "0px";
            estiloCategoriasImagenes.value = result.data.retorno.estiloCategoriasImagenes ? result.data.retorno.estiloCategoriasImagenes : "F";
            configCatsImagenes.value.lg = result.data.retorno.cantItemsCarouselCatsLg ? result.data.retorno.cantItemsCarouselCatsLg : 7;
            configCatsImagenes.value.md = result.data.retorno.cantItemsCarouselCatsMd ? result.data.retorno.cantItemsCarouselCatsMd : 4;
            configCatsImagenes.value.colorFlechas = result.data.retorno.codigoColorFlechasBannerCategorias ? result.data.retorno.codigoColorFlechasBannerCategorias : "#e8dede";
            estiloBuscador.value = result.data.retorno.estiloBuscador ? result.data.retorno.estiloBuscador : "B";
            titleFont.value = result.data.retorno.fuenteTitulo && result.data.retorno.fuenteTitulo != "-1" ? getFont(result.data.retorno.fuenteTitulo) : "";
            titleFontStyle.value = result.data.retorno.fuenteTituloEstilo ? result.data.retorno.fuenteTituloEstilo : 300;
            titleAlignament.value = result.data.retorno.fuenteTituloAlineacion && result.data.retorno.fuenteTituloAlineacion != "C" ? "start" : "center";
            subtitleFont.value = result.data.retorno.fuenteSubtitulo && result.data.retorno.fuenteSubtitulo != "-1" ? getFont(result.data.retorno.fuenteSubtitulo) : "";
            subtitleFontStyle.value = result.data.retorno.fuenteSubtituloEstilo ? result.data.retorno.fuenteSubtituloEstilo : 300;
            footerHeightClient.value = result.data.retorno.alturaPie ? result.data.retorno.alturaPie + "px" : "100px";
            bgFootClient.value = result.data.retorno.codigoColorPie ? result.data.retorno.codigoColorPie : navClientColor.value;
            colorFuenteCtegoriasImg.value = result.data.retorno.codigoColorFuenteBannerCategorias ? result.data.retorno.codigoColorFuenteBannerCategorias : navClientIcon.value;
            footTextClient.value = result.data.retorno.codigoColorFuentePie ? result.data.retorno.codigoColorFuentePie : navClientIcon.value; 
            titleFontColor.value = result.data.retorno.codigoColorTitulos ? result.data.retorno.codigoColorTitulos : navClientIcon.value; 



            setCodigosRedes();
            obtenerPlanesDescuentos();
          });

        $axios
          .get(`/categorias/grupoCategoria`)
          .then((result) => {
            let url = "categorias";
            tieneGruposCategorias.value = result.data.retorno.length;
            if (tieneGruposCategorias.value) {
              url += "/grupo";
            }
            $axios
              .get(`/${url}`)
              .then((result) => {
                categorias.value = result.data.retorno;
              });
          });

        $axios.get(`/productos/search`).then((result) => {
          productosSearch.value = result.data.retorno.colReturn;
        });
        isLoading.value = false;
      }
    )

    const obtenerPlanesDescuentos = () => {
      if (issuserParam.value.planDescuentoBin != undefined && issuserParam.value.planDescuentoBin === "S") {
        $axios
          .get(`/descuentos/planes`)
          .then((result) => {
            if (result.data.cod_respuesta == "00") {
              descuentos.value = result.data.retorno
            }
          });

      }
    }

    const setCodigosRedes = () => {
      if (extraerCodigoScript(FPixel.value)) {
        const fbScript = document.createElement('script')
        fbScript.innerHTML = extraerCodigoScript(FPixel.value);
        document.head.appendChild(fbScript);
      }

      if (EventoMC.value != "") {
        let script = document.createElement('script')
        script.innerHTML = EventoMC.value;
        document.head.appendChild(script);
        //eval(EventoMC.value)
      }

      if (VDominio.value != "") {
        const metaEtiqueta = document.createElement("meta");
        const name = extraerValorPropiedad(VDominio.value, "name");
        const content = extraerValorPropiedad(VDominio.value, "content");

        metaEtiqueta.setAttribute("name", name);
        metaEtiqueta.setAttribute("content", content);
        document.head.appendChild(metaEtiqueta);
      }
    }

    const cargarProductos = async (params, fn) => {

      let url = `/productos`;
      params.deposito = issuserParam.value.idDeposito;
      $axios
        .post(url, params)
        .then((result) => {
          productos.value = result.data.retorno;
          if (fn) {
            fn(result.data.retorno);
          }
        });

      return productos;
    };


    const getApiGeneric = (url, params, fn) => {
      return $axios
        .get(url, { params })
        .then((result) => {
          if (fn) {
            fn(result.data);
          }
          return result.data;
        });
    };

    const postApiGeneric = (url, params, fn) => {
      return $axios
        .post(`${url}`, params)
        .then((result) => {
          if (fn) {
            fn(result.data);
          }
          return result.data;
        });
    };


    if (localStorage.getItem("product")) {
      cart.value = JSON.parse(localStorage.getItem("product"));
    }

    const saveProducto = (prod, fn, saveLiteral) => {

      if (prod) {

        if (issuserParam.value.idDeposito >= 0 && prod.cantidad > prod.stock) {
          Swal.fire(
            {
              icon: "warning",
              title: "No hay Stock Suficiente.",
              confirmButtonText: "Ok",
              confirmButtonColor: styleClass.value,
            }
          );
          prod.cantidad = Math.floor(prod.stock) <= 0 ? 1 : Math.floor(prod.stock);
          prod.sinStock = true;
          return prod;
        }

        if (prod.cantidad === "" || prod.cantidad <= 0) {
          Swal.fire({
            title: "La cantidad debe ser mayor a 0.",
            icon: "warning",
            confirmButtonColor: styleClass.value
          });
          prod.cantidad = 1;
          return prod;
        }

        let index = -1;
        index = cart.value.findIndex((product) => product.id === prod.codigo);


        if (index >= 0) {
          let cantidadTotal = saveLiteral
            ? prod.cantidad
            : cart.value[index].cantidad + prod.cantidad;
          if (issuserParam.value.idDeposito >= 0 && cantidadTotal > prod.stock) {
            Swal.fire(
              {
                icon: "warning",
                title: "La cantidad del carrito supera la disponible",
                confirmButtonText: "Ok",
                confirmButtonColor: styleClass.value,
              }
            );
            prod.cantidad = Math.floor(prod.stock);
            prod.carritoSuperiorStock = true;
            return prod;
          }
          cart.value[index].cantidad = cantidadTotal;
        } else {
          prProducto = { id: prod.codigo, cantidad: prod.cantidad };
          cart.value.push(prProducto);
        }
      }

      localStorage.setItem("product", JSON.stringify(cart.value));

      if (fn) {
        fn(cart);
      }
      return prod;
    };

    /*     favList.value = localStorage.getItem("fav")
          ? JSON.parse(localStorage.getItem("fav"))
          : []; */

    provide("descuentos", descuentos);
    provide("configCatsImagenes", configCatsImagenes);
    provide("estiloCategoriasImagenes", estiloCategoriasImagenes);
    provide("navCodigoColorIcono", navCodigoColorIcono);
    provide("categoriasFontColorClient", categoriasFontColorClient);
    provide("categoriasColorClient", categoriasColorClient);
    provide("appName", appName);
    provide("subcategoriesVisible", subcategoriesVisible);
    provide("categoriaSelected", categoriaSelected);
    provide("subSelected", subSelected);
    provide("decimal", decimal);
    provide("categorias", readonly(categorias));
    provide("tieneGruposCategorias", readonly(tieneGruposCategorias));
    provide("issuserParam", readonly(issuserParam));
    provide("productos", productos);
    provide("productosSearch", productosSearch);
    provide("cargarProductos", cargarProductos);
    provide("paramsProductos", params);
    provide("pagActive", pagActive);
    provide("getApiGeneric", getApiGeneric);
    provide("postApiGeneric", postApiGeneric);
    provide("cart", cart);
    provide("urlAPI", urlApi);
    provide("pedidoFE", ref({}));
    provide("datos", ref({}));
    provide("productsComplete", productComplete);
    provide("objfactura", ref({}));
    provide("objEnvioPedido", ref({}));
    provide("saveProducto", saveProducto);
    provide("styleClass", styleClass);
    provide("inicioHash", inicioHash);
    provide("favList", favList);
    provide("app_version", appVersion);
    provide("api_version", apiVersion)
    provide("terminosYCondiciones", terminosYCondiciones)
    provide("navClientColor", navClientColor)
    provide("mostrarCategoriasNavbar", mostrarCategoriasNavbar);
    return { titleFontColor, footTextClient, colorFuenteCtegoriasImg, bgFootClient, footerHeightClient, subtitleFontStyle, subtitleFont, titleAlignament, titleFontStyle, titleFont, darkerColor, isLoading, styleClass, navClientColor, navClientIcon, navCodigoColorIcono, navsizeDefectoImagen, navsizeDefectoImagenTelefono, navsizeLogo, navsizeNavBar, sizeHeaderNavBar, margenesLaterales };
  },
};
</script>
<style lang="scss">
.dropdown-item.active {
  background-color: v-bind(styleClass) !important;
}

.dropdown-item:hover {
  background-color: v-bind(styleClass) !important;
}

.dark-mode .dropdown-item.active {
  color: var(--bs-dark) !important;
  background-color: v-bind(styleClass) !important;
}

.dark-mode .dropdown-item:hover {
  color: var(--bs-dark) !important;
  background-color: v-bind(styleClass) !important;
}

.text-client {
  color: v-bind(navClientIcon) !important;
}

.shadow-client {
  border: 1px v-bind(footTextClient) solid !important;
}

.dark-mode .text-client {
  color: v-bind(navClientIcon) !important;
}

.bg-client {
  color: v-bind(navClientColor) !important;
  background-color: v-bind(navClientIcon) !important;
}

.dark-mode .bg-client {
  color: var(--bs-dark) !important;
  background-color: v-bind(navClientIcon) !important;
}

.btn-client {
  color: var(--bs-white) !important;
  background-color: v-bind(styleClass) !important;
}

.btn-client-invertido {
  color: v-bind(styleClass) !important;
  background-color: rgb(255, 255, 255) !important;
}

.btn-client-border {
  border: 1px solid !important;
  border-color: v-bind(styleClass) !important;
}

.bg-nav-client {
  background-color: v-bind(navClientColor) !important;
}

.bg-foot-client {
  background-color: v-bind(bgFootClient) !important;
}

.dark-mode .bg-nav-client {
  background-color: #222 !important;
}

.dark-mode .btn-client {
  color: var(--bs-dark) !important;
  background-color: v-bind(styleClass) !important;
}

.bg-semi-black-transparent {
  background-color: #0000005b !important;
}

.bg-semi-black-transparent .nav-link:hover {
  background-color: #00000083 !important;
}

.card-hover:hover {
  background-color: #0000005b !important;
}

/* .btn-buscar {
  color: v-bind(navCodigoColorIcono) !important;
} */

.client-navbar {
  //height: 350px!important;
  line-height: v-bind(navsizeNavBar) !important;
}

.container-nav {
  height: v-bind(sizeHeaderNavBar) !important;
}

.client-size-logo {
  height: v-bind(navsizeLogo) !important;
}


@media(min-width: 576px) {
  .img-escritorio {
    height: v-bind(navsizeDefectoImagen) !important;
  }

  .padding {
    padding-bottom: 20% !important;
  }
}

@media(min-width: 980px) {
  .padding {
    padding-bottom: 5% !important;
  }
}

@media(min-width: 1389px) {
  #carouselDynamic {
    height: auto !important;
    margin-top: -10px !important;
  }
}

@media(max-width: 1389px) {
  #carouselDynamic {
    height: auto !important;
    padding-top: 0px !important;
  }
}


@media(max-width: 574px) {
  .img-telefono {
    height: v-bind(navsizeDefectoImagenTelefono) !important;
  }

  #carouselDynamic img {
    height: auto !important;
  }

  #carouselDynamic {
    height: auto !important;
  }


  .padding {
    padding-bottom: 25% !important;
  }

  .client-size-logo {
    height: auto !important;
  }

}

.btn-redsocial-footer {
  margin-bottom: 13%;
}

#categorias .nav-link:hover {
  background-color: v-bind(darkerColor) !important;
}

.list-buscador:hover {
  background-color: v-bind(navClientColor) !important;
  cursor: pointer !important;
}

@media(min-width: 1200px) {
  #div-nav {
    padding-left: v-bind(margenesLaterales) !important;
    padding-right: v-bind(margenesLaterales) !important;
  }
}

.title-client {
  font-family: v-bind(titleFont) !important;
  font-weight: v-bind(titleFontStyle) !important;
  text-align: v-bind(titleAlignament) !important;
  color: v-bind(titleFontColor) !important;
}

.subtitle-client {
  font-family: v-bind(subtitleFont) !important;
  font-weight: v-bind(subtitleFontStyle) !important;
}

#foot {
  height: v-bind(footerHeightClient) !important;
}

@media(max-width: 856px) {
  #foot {
    height: 130px !important;
  }
}

@media(max-width: 576px) {
  #foot {
    height: 180px !important;
  }

}

.container-marcas {
  padding-top: 35px !important;
}


.precio-descuento {
  color: rgb(231, 32, 32) !important;
}

.text-categorias-client {
  color: v-bind(colorFuenteCtegoriasImg) !important;
}

.foot-text-client{
   color: v-bind(footTextClient)!important;
}
</style>
