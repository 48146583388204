<template>
  <loading-data v-if="loadingData" :appName="appName" tipo="T" />
  <div class="home">
    <Carousel />
    <div class="px-5 padding">
      <div class="container-marcas justify-content-center d-flex" v-if="issuserParam.mostrarMarcas && issuserParam.mostrarMarcas === 'S'">
         <ListMarcas/>
      </div>
      <div class="row">
        <div v-if="issuserParam.categoriasImagenes && issuserParam.categoriasImagenes === 'S'"
          class="col d-none d-sm-none d-md-block d-lg-block pb-2">
          <ListCategorias  :type="estiloCategoriasImagenes" :configCatsImagenes="configCatsImagenes"/>
        </div>
      </div>
      <div class="row d-flex justify-content-center" v-if="issuserParam.mostrarCategoriaDestacados && issuserParam.mostrarCategoriaDestacados === 'S'">  
          <FeaturedArticles :params="issuserParam" :colorFlechas="configCatsImagenes.colorFlechas"/>   
      </div>
      <div class="row">
        <div class="col" id="articles">
          <Articles pageTrack="Home" />
        </div>
      </div>
      <div class="row">
        <div class="col pb-5 pb-md-0 pb-lg-0">
          <MostBuyArticles />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject, watch, onMounted, onUnmounted, ref } from "vue";
import Carousel from "@/components/Carousel.vue";
import Articles from "@/components/Articles.vue";
import ListCategorias from "@/components/ListCategorias.vue";
import MostBuyArticles from "@/components/MostBuyArticles.vue";
import FeaturedArticles from "@/components/FeaturedArticles.vue";
import ListMarcas from "@/components/ListMarcas.vue";
import LoadingData from "@/components/LoadingData.vue";
export default {
  name: "Home",
  components: {
    Carousel,
    Articles,
    ListCategorias,
    MostBuyArticles,
    LoadingData,
    FeaturedArticles,
    ListMarcas
  },
  setup() {
    const issuserParam = inject("issuserParam");
    const estiloCategoriasImagenes = inject("estiloCategoriasImagenes");
    const cargarProductos = inject("cargarProductos");
    const paramsProductos = inject("paramsProductos");
    const pagActive = inject("pagActive");
    const loadingData = ref(true);
    const appName = inject("appName");
    const configCatsImagenes = inject("configCatsImagenes");

    const instanceProductos = () => {
      pagActive.value = 1;
      paramsProductos.value.start = pagActive.value;
      paramsProductos.value.categoria = -1;
      paramsProductos.value.grupo = -1;
      paramsProductos.value.search = "";
      paramsProductos.value.orderby = 1;
      delete paramsProductos.value.productos;
      cargarProductos(paramsProductos.value, () => {
        setTimeout(
          ()=>{
            loadingData.value = false;
          },200)
      });
    }

     if (issuserParam.value.idLP) {
      instanceProductos();
    } 
 
    watch(
      () => issuserParam.value,
      (newParams) => {
        if (!newParams) return;
        instanceProductos();
      }
    );

    onMounted(() => {
      window.scrollTo(0, 0);
    });

    onUnmounted(
      () => {
        // CHECKEO QUE NO HAYA VINCULACIONES CON EL PIXEL
        if (document.getElementById("divPixel") != undefined) {
          document.head.removeChild(document.getElementById("divPixel"));
        }
      }
    );
    return {
      loadingData,
      appName,
      estiloCategoriasImagenes,
      issuserParam,
      configCatsImagenes
    }
  },
  methods: {
    changeDarkMode() {
      document.body.classList.toggle("dark-mode");
    },
  },
};
</script>
