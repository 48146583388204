<template>
  <div class="justify-content-center align-items-center d-flex" :class="tipo === 'T' ? 'loading' : ''">
    <div class="text-center">
      <div :class="classes" class="row" v-if="appName">
        <div class="col">
          <h4>{{ appName }}</h4>
        </div>
      </div>
      <div class="row" :class="classes">
        <div class="col">
          <img src="/img/loading.gif" height="50" />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <h5>{{ msg }}</h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import { inject } from 'vue';
export default {
  name: 'loading-data',
  props: {
    msg: String,
    classes: String,
    appName:String,
    tipo: String
  },
/*   setup(){
    const appName = inject("appName");
    return {
      appName
    }
  } */
}
</script>

<style>
.loading{
  position: fixed!important; 
  z-index: 9999!important;
  top: 0 !important;
  height: 100vh!important;
  width: 100%!important;
  background-color: white!important;
}

</style>