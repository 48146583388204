<template>
  <div id="carouselDynamic" class="carousel slide d-sm-block" data-bs-ride="carousel">
    <!--- SI DIENE ACTIVADOS LOS BOTONES INDICADORES DESDE LAS PROPS-->
    <div class="carousel-indicators mb-0">
      <button type="button" data-bs-target="#carouselDynamic" v-for="(item, index) in urlsCarousel" :key="item.codigo"
        :data-bs-slide-to="index" :class="index === 0 ? 'active' : ''" :aria-current="index === 0 ? 'true' : 'false'"
        :aria-label="item.codigo"></button>
    </div>
    <div class="carousel-inner h-100">
      <div class="carousel-item h-100" data-bs-interval="4000" :class="index === 0 ? 'active' : ''"
        v-for="(item, index) in urlsCarousel" :key="item.codigo">
        <img :src="item.ruta + item.imagen" class="d-block w-100 h-auto size" :alt="item.imagen" />
      </div>
    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#carouselDynamic" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#carouselDynamic" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
</template>

<script>
import { inject, ref } from "vue";
export default {

  setup() {
    let urlsCarousel = ref([]);
      const $axios = inject("$axios");
      $axios
        .get(`/productos/banner/destacados`)
        .then((result) => {
          urlsCarousel.value = result.data.retorno;
        });
    return { urlsCarousel };
  },

};
</script>
<style>
/* @media(max-width: 576px){
  #carouselDynamic{
      height: 155px;
  }
  .size{
     min-height: 100% !important;
    padding-bottom: 22%!important;
    bottom: 0px;
  }
}*/
</style>