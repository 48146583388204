<template>
  <loading-data v-if="loadingData" msg="Cargando Catálogo" tipo="T" />
  <div class="w-100" v-else>
    <div class="row w-100 h-100 mx-0"
      v-if="categoriaSelected && categoriaSelected.imagenGrupoCatalogo != '' && categoriaSelected.imagenGrupoCatalogo != null">
      <img class="px-0" :src="categoriaSelected.pathCatalogo + categoriaSelected.imagenGrupoCatalogo" />
    </div>
    <div class="container px-5 padding"
      :class="categoriaSelected && categoriaSelected.imagenGrupoCatalogo != '' && categoriaSelected.imagenGrupoCatalogo != null ? 'pt-0 mt-0' : 'pt-md-5 pt-lg-5 pt-5 mt-3'">
      <div class="row pt-sm-5 pt-md-5 pt-5 align-items-start text-left d-none d-sm-block d-md-block d-lg-block">
        <BackButton class="align-items-start" />
      </div>
      <div class="row pb-5 pt-4 p-sm-0 p-md-0 p-lg-0 text-center">
        <h3 :class="issuserParam.fuenteTitulo && issuserParam.fuenteTitulo != '-1' ? 'title-client' : ''">{{
          categoriaSelected && subSelected ? capitalizeFirstLetter(subSelected.nombre) : categoriaSelected ?
          capitalizeFirstLetter(categoriaSelected.nombreGrupo) : "" }}
        </h3>
        <div class="col justify-content-around pb-5">
          <Articles pageTrack="Catalog" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import { watch, inject, ref, onMounted, onBeforeUnmount } from 'vue';
import Articles from "@/components/Articles.vue";
import LoadingData from "@/components/LoadingData.vue";
import router from "@/router";
import BackButton from "@/components/BackButton.vue";
import { capitalizeFirstLetter } from "@/composable/functions";
export default {
  components: { Articles, LoadingData, BackButton },
  setup() {
    const route = useRoute();
    const paramsProductos = inject("paramsProductos");
    const cargarProductos = inject("cargarProductos");
    const categorias = inject("categorias");
    const pagActive = inject("pagActive");
    const nombre = ref("Catálogo ");
    let loadingData = ref(true);
    const issuserParam = inject("issuserParam");

    const subSelected = inject("subSelected");
    const categoriaSelected = inject("categoriaSelected");
    const subcategoriesVisible = inject("subcategoriesVisible");

    const instanceCatalogo = () => {
      if (!route.params.categoria && paramsProductos.value.search == "") {
        router.push({ name: "Home" });
        return;
      }
      if (route.params.categoria) {
        const categoria = categorias.value.find(categoria => {
          return categoria.nombreGrupo.toLowerCase() == route.params.categoria.toLowerCase();
        });
        categoriaSelected.value = categoria;
        paramsProductos.value.grupo = categoriaSelected.value.codigoGrupo;
      }
      if (route.params.subcategoria) {
        const subcategoria = categoriaSelected.value.items.find(subcat => {
          return subcat.nombre.toLowerCase() == route.params.subcategoria.toLocaleLowerCase();
        });
        subSelected.value = subcategoria;
        paramsProductos.value.categoria = subSelected.value.codigo
      }
      pagActive.value = 1;
      paramsProductos.value.start = pagActive.value;
      delete paramsProductos.value.productos;
      cargarProductos(paramsProductos.value, () => {
        loadingData.value = false;
      });
    };

    if (categorias.value.length) {
      instanceCatalogo();
    }

    watch(
      () => categorias.value,
      (newParams) => {
        if (!newParams) return;
        instanceCatalogo();
      }
    );

    onMounted(() => {
      window.scrollTo(0, 0);
    });

    const resetearParams = () =>{
      const rutaSuguiente = router.currentRoute.value.name;
        if (rutaSuguiente != "DetailArticle" && subSelected.value && Object.keys(subSelected.value).length > 0) {
          subSelected.value.clase = '';
          subSelected.value = null;
          categoriaSelected.value = null;
          paramsProductos.value.grupo = -1;
          paramsProductos.value.categoria = -1;
          subcategoriesVisible.value = false;
          document.querySelectorAll(".collapse.show").forEach((elemento) => {
            elemento.classList.remove('show');
          })
        }
    }

    onBeforeUnmount(
      () => {
        resetearParams();
      }
    );

    return { nombre, loadingData, subSelected, categoriaSelected, issuserParam, capitalizeFirstLetter }
  },
};
</script>

<style></style>